import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { ISongResponse } from "@utilsmodels/SongResponse";
import { networkClient } from "./networkClient";

export const GET_SONGS_URL = "/songs";

export const getSongAlbumsApi = async (songId : string) => {
  const { data } = await networkClient.get(`/songs/${songId}/albums`);
  return data;
};
