import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { ISongResponse } from "@utilsmodels/SongResponse";
import { networkClient } from "./networkClient";

export const GET_SONGS_URL = "/songs";

export interface IGetAllSongsResponse
  extends IPaginationResponse<ISongResponse> {}

export const getAllSongsApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get<IGetAllSongsResponse>(queryKey[0]);
  return data;
};

export const getSongsSearchApi = async ({ title }: any) => {
  const { data } = await networkClient.get(GET_SONGS_URL, {params : {title:title}});
  return data;
};