import { getTimeAgo } from "@utils/helpers/getTimeAgo";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { Skeleton } from "components/Skeleton";
import { Text, Image, Box, HStack, VStack, Divider } from "native-base";
import { FC } from "react";
import { IPostProps } from "@utilsmodels/PostProps";
import { EmptyButton } from "components/EmptyButton";
import { IconEdit, IconNotification, IconTrash } from "@tabler/icons-react";
import { getKText } from "@utils/helpers";
import { useModal } from "@contexts";
import { ModalTypes } from "components/Modals";

export const Reel: FC<IPostProps<IPostResponse>> = ({
  data,
  isLoading,
  onDelete = () => {},
  onEdit = () => {},
}) => {
  const { showModal } = useModal();
  return (
    <Box borderRadius={8} my={3} minW={{ base: 200 }}>
      {isLoading || data === null ? (
        <Skeleton w="200px" h="300px" borderRadius="lg" />
      ) : (
        data && (
          <>
            <Image
              source={{
                uri: data.thumbnailUrl ?? data.files?.[0]?.thumbnailPath,
              }}
              alt="reel"
              w="200px"
              h="300px"
              borderRadius="lg"
            />
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                backgroundImage:
                  "linear-gradient(to bottom, transparent, black)",
                borderRadius: 8,
              }}
            >
              <Box position="absolute" h="100%" w="100%">
                <VStack
                  h="100%"
                  px={3}
                  justifyContent="space-between"
                  pb="2"
                  pt="2"
                >
                  <HStack justifyContent={"flex-end"} space="1" w="100%">
                  <EmptyButton onClick={() => showModal(ModalTypes.CREATE_NOTIFICATION, {id:data.id, channel: "REELS"})}>
                    <IconNotification color="white" size={20} />
                  </EmptyButton>
                    <EmptyButton onClick={() => onEdit(data)}>
                      <IconEdit color="white" size={20} />
                    </EmptyButton>
                    <EmptyButton onClick={() => onDelete(data)}>
                      <IconTrash color="white" size={20} />
                    </EmptyButton>
                  </HStack>
                  <VStack>
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {getKText(data?.views ?? 0)} views
                    </Text>
                    <Text fontSize="md" fontWeight="medium" color="white">
                      {data.description}
                    </Text>
                  </VStack>
                </VStack>
              </Box>
            </div>
          </>
        )
      )}
    </Box>
  );
};
