import { IModalItem, IModalProps } from "@utils/models";
import { FileUpload } from "components/FileUpload";
import { TextAreaField } from "components/TextAreaField";
import { TextField } from "components/TextField";
import { Button, DatePickerField } from "@components/";
import {
  Modal,
  HStack,
  Text,
  Image,
  TextArea,
  VStack,
  Box,
  Heading,
  CheckIcon,
  Select,
  Checkbox,
} from "native-base";
import { useState } from "react";
import { useModal } from "@contexts";
import { withFormField } from "@utils/hoc";
import { useForm } from "react-hook-form";
import { useTranslation } from "@utils/hooks";
import { useMutation } from "@tanstack/react-query";
import { sendNewNotificationApi } from "@api";

export interface CreateNotificationProps extends IModalProps {}

const TextFieldForm = withFormField(TextField);
const TextAreaFieldForm = withFormField(TextAreaField);
// const DatepickerFieldForm = withFormField(DatePickerField);
const NCheckbox = (props: any) => <Checkbox {...props} />;
const NSelect = (props: any) => <Select {...props} />;

const CheckboxFieldForm = withFormField(NCheckbox, {
  formContainerProps: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    space: 2,
    // maxW: "250px",
  },
});

const SelectFieldForm = withFormField(NSelect, {
  formContainerProps: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    space: 2,
    // maxW: "250px",
  },
});

export const CreateNotification: React.FC<CreateNotificationProps> = ({
  payload,
}) => {
  const { t } = useTranslation({ keyPrefix: "notifications" });

  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('ru');

  const [ok, setOk] = useState(false);
  const { hideModal } = useModal();

  const { control, handleSubmit, formState, reset: resetForm } = useForm<any>({
    defaultValues: {
      title: "",
      link: "",
      description: "",
      language: 'ru',
      premium: false,
      free: false
    },
  });

  const {
    mutateAsync: uploadPost,
    isLoading: isUploadLoading,
    isSuccess: isUploadSuccess,
    reset,
  } = useMutation(
    (args: any) => (sendNewNotificationApi(args))
  );
  
  const submit = (form: any) => {
    setLoading(true);
    if(payload.id) {
      form.link = payload.id.toString();
      form.channel = payload.channel;
    } else {
      form.channel = "NEWS";
    }
    if(!selectedLanguage) {
      form.language = 'ru';
    } else {
      form.language = selectedLanguage;
    }

    uploadPost(form).then(()=> {
      hideModal();
      resetForm();
      setLoading(false);
    })
  };

  const sendNewNotification = () => {

  }

  return (
    <Modal.Content maxWidth="946px">
      <Modal.CloseButton />
      <Modal.Header>{t("forms.sendNew.title")}</Modal.Header>
      <Modal.Body px="0" py="0">
        {ok ? (
          <VStack
            h="4"
            height={"360px"}
            alignItems="center"
            justifyContent="center"
            space="4"
          >
            <Heading bold color="black">
              Upload success
            </Heading>
            <Box w="20%">
              <Button onClick={() => hideModal()}>
                <Text bold color="white">
                  Close
                </Text>
              </Button>
            </Box>
          </VStack>
        ) : (
          <VStack px="6" py="6" space="4">
            <TextFieldForm form={{ control, name: "title", rules: { required: true} }} label={t("forms.sendNew.fields.title.label")} />
            <TextAreaFieldForm
              form={{ control, name: "description", rules: { required: true}  }}
              label={t("forms.sendNew.fields.content.label")}
            />
            {
              !payload.id && 
            <TextFieldForm form={{ control, name: "link", rules: { 
              required: { "value":true, "message":"A valid Url is required"}, 
              pattern:{ value:/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/, message:'Please enter a valid url' }}} } 
              label={t("forms.sendNew.fields.link.label")} />
            }


        <SelectFieldForm
            onValueChange={setSelectedLanguage}
            form={{ control, name: "language" }}
            label={"Language"}
          >
            <Select.Item label="Russian" value="ru" />
            <Select.Item label="English" value="en" />

            </SelectFieldForm>

          <CheckboxFieldForm
            form={{ control, name: "premium" }}
            label={"Premium"}
          />
          <CheckboxFieldForm
            form={{ control, name: "free" }}
            label={"Free"}
          />
            <Button onClick={handleSubmit(submit)} isLoading={isUploadLoading}>
              <Text bold color="white">
                {t("buttons.addNotification")}
              </Text>
            </Button>
          </VStack>
        )}
      </Modal.Body>
    </Modal.Content>
  );
};
