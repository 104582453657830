import React, { FC, useState } from "react";
import { Box, IInputProps, theme } from "native-base";
import { TextField } from "./TextField";
import { IconSearch } from "@tabler/icons-react";
import { Button } from "./Button";
import { useTranslation } from "@utils/hooks";
export interface SearchFieldProps extends IInputProps {}

export const SearchField: FC<SearchFieldProps> = ({ ...props }) => {

  const [value, setValue] = useState("");
  const { t } = useTranslation({ keyPrefix: "music.forms" });
  return (
    <TextField
      type="text"
      bg="gray.100"
      borderRadius="4px"
      py="4"
      onChange={(val:string)=>{setValue(val)}}
      borderBottomColor={"transparent"}
      InputRightElement={
        <Box pr="4">
          <Button isLoading={props.isLoading} onClick={()=> {props.onSearch(value)}}><IconSearch size={20} color={theme.colors.gray[400]} /></Button>
        </Box>
      }
      autoCapitalize="none"
      placeholder={t('search')}
      fontSize="md"
      {...props}
    />
  );
};
