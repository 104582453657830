import { getTimeAgo } from "@utils/helpers/getTimeAgo";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { Skeleton } from "components/Skeleton";
import { Text, Image, Box, HStack, VStack, Divider } from "native-base";
import { FC, useEffect, useRef, useState } from "react";
import { IPostProps } from "@utilsmodels/PostProps";
import { IconEdit, IconNotification, IconTrash } from "@tabler/icons-react";
import { EmptyButton } from "components/EmptyButton";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Pagination, Navigation } from "swiper";
import { FileThumbnail } from "components/FileThumbnail";
import { useWindowDimensions } from "@utils/hooks";
import { useModal } from "@contexts";
import { ModalTypes } from "components/Modals";

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const Post: FC<IPostProps<DeepPartial<IPostResponse>>> = ({
  data,
  isLoading = true,
  onDelete = () => {},
  onEdit = () => {},
}) => {
  const { width } = useWindowDimensions();
  const ref = useRef(null);
  const { showModal } = useModal();

  return (
    <Box borderRadius={8} my={3} alignSelf="center">
      {isLoading || data === null ? (
        <VStack space="4">
          <Skeleton w="100%" h={462} />
          <Skeleton w="100%" h={50} />
        </VStack>
      ) : (
        data && (
          <VStack >
            <Box overflowX="hidden" w={width} maxWidth={400} ref={ref} alignSelf="center" >
              <div>
                <style>
                  {`
                  .wrapper {
                    width: ${width}px;
                    max-width: 400px;
                  }
                  `}
                </style>
              </div>

              <Swiper
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                navigation
                pagination
                wrapperClass="wrapper"
                style={{ alignItems: 'center', justifyContent: 'center'}}
              >
                {data?.files?.map((file, index) => (
                  <SwiperSlide key={index} style={{ alignItems: 'center'}}>
                    {({ isActive, isVisible }) => {
                      return (
                        <FileThumbnail
                          width="100%"
                          height={462}
                          
                          file={file?.filePath ?? ""}
                        />
                      );
                    }}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            <Box width={400}>
              <VStack alignItems="stretch" px={3} pb={3}>
                <HStack alignItems="center" mt={1}>
                  <Text fontSize="sm">{data?.description}</Text>
                </HStack>
                <Text fontSize="sm" mt={1} color="gray.500">
                  {/* {getTimeAgo(new Date(String(data?.createdAt)))} */}
                </Text>
                <HStack justifyContent={"flex-end"} space="1" w="100%">
                
                  <EmptyButton onClick={() => showModal(ModalTypes.CREATE_NOTIFICATION, {id:data.id, channel: "POSTS"})}>
                    <IconNotification color="black" size={20} />
                  </EmptyButton>

                  <EmptyButton onClick={() => onEdit(data)}>
                    <IconEdit color="black" size={20} />
                  </EmptyButton>
                  <EmptyButton onClick={() => onDelete(data)}>
                    <IconTrash color="black" size={20} />
                  </EmptyButton>
                </HStack>
              </VStack>
            </Box>

            <Divider />
          </VStack>
        )
      )}
    </Box>
  );
};
