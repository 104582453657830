import { QueryClientProvider } from "@tanstack/react-query";
import { NativeBaseProvider } from "native-base";
import { theme } from "theme";
import { BrowserRouter } from "react-router-dom";
import { RootRouter } from "routes";
import { queryClient } from "@api";
import { createGlobalStyle } from "styled-components";
import { AuthContextProvider, ModalProvider } from "@contexts";
import { registeredModals } from "@components/Modals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ConfigProvider } from "antd";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Urbanist', serif;
    margin: 0;
    background-color: white;
    overflow: hidden;
  }
`;

export default function App() {
  return (
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#C5915E',
        colorBorder: "C5915E",
        colorLinkHover: '#C5915E',
        borderRadius: 2,
      },
    }}
  >
    <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider theme={theme}>
          <GlobalStyle />
          <AuthContextProvider>
            <ModalProvider modals={registeredModals}>
              <RootRouter />
            </ModalProvider>
          </AuthContextProvider>
        </NativeBaseProvider>
      </QueryClientProvider>
    </BrowserRouter>
    </DndProvider>
    </ConfigProvider>
  );
}
