import { Button } from "@components";
import { useTranslation } from "@utils/hooks";
import { defaultThumbnailUrl } from "@utilsmock/defaultThumbnailUrl";
import { HStack, useToast, VStack, Box, Divider, AspectRatio, Image, Center, Stack, Heading, Text } from "native-base";
import { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableSongCard } from "./DraggableSongCard";
import update from 'immutability-helper'


export default (props:any) => {

const {t} = useTranslation({ keyPrefix: "music.forms" }); 

  const findCard = 
    (id:string) => {
      const card = props.songs.filter((c:any) => `${c.id}` === id)[0]
      return {
        card,
        index: props.songs.indexOf(card),
      }
    };

  const moveCard = (id:string, atIndex:number) => {
      const { card, index } = findCard(id);
      props.setSelectedSongs(
        update(props.songs, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      )
  };

  const removeFromSelected = (song:any) => {
    const { index } = findCard(song.id);
    let newCards = [...props.songs];
    newCards.splice(index, 1);
    props.setSelectedSongs(newCards);
  }

  const [, drop] = useDrop(() => ({ accept: "song" }))

return (

<VStack space={6} >
<div ref={drop}>
{
    props?.songs?.map((song:any)=> {
        if(props.exclude && props.exclude.length > 0) {
          let excluded = props.exclude.find((toExclude:any)=> {return song.id === toExclude.id});
          if(excluded) {
            return;
          }
        }
        return (<DraggableSongCard key={song.id} song={song} moveCard={moveCard} findCard={findCard} removeFromSelected={removeFromSelected}></DraggableSongCard>)
    })
}
</div>
</VStack>);

}