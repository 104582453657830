import {
  Box,
  Center,
  HStack,
  IconButton,
  Pressable,
  Stack,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode, useCallback, useContext, useState } from "react";
import { Outlet, useNavigation, useOutletContext } from "react-router-dom";
import {
  IconLogout,
  IconHome,
  IconBrandGoogleAnalytics,
  IconPlaylist,
  IconSettings,
  IconVideo,
  IconNotification,
  IconUsersGroup,
  IconMusic,
  IconAlbum,
  IconChecklist,
} from "@tabler/icons-react";
import { Menu } from "@components/Menu";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "@utils/hooks";

export interface CommonLayoutProps extends InterfaceBoxProps {}

export const CommonLayout: FC<CommonLayoutProps> = ({ children, ...props }) => {
  const { t } = useTranslation("translation", { keyPrefix: "menu" });
  const defaultMenuOptions = [
    {
      id: "home",
      name: t("home"),
      leftIcon: IconHome,
      path: "",
    },   
    {
      id: "music",
      name: t("music"),
      leftIcon: IconPlaylist,
      path: "music",
      options: [
        {
          id: "collections",
          name: t("collections"),
          leftIcon: IconAlbum,
          path: "collections",
        },
        {
          id: "songs",
          name: t("songs"),
          leftIcon: IconMusic,
          path: "music",
        },
      ],
    },
    {
      id: "videos",
      name: t("videos"),
      leftIcon: IconVideo,
      path: "videos",
    },
    {
      id: "comments",
      name: t("comments"),
      leftIcon: IconChecklist,
      path: "comments",
    },
    {
      id: "notifications",
      name: t("notifications"),
      leftIcon: IconNotification,
      path: "notifications",
    },
    {
      id: "users",
      name: t("users"),
      leftIcon: IconUsersGroup,
      path: "users",
      options: [
        {
          id: "followers",
          name: t("followers"),
          leftIcon: IconUsersGroup,
          path: "followers",
        },
        {
          id: "abusers",
          name: t("abusers"),
          leftIcon: IconUsersGroup,
          path: "abusers",
        },
        {
          id: "cancellation",
          name: t("pendingCancellation"),
          leftIcon: IconUsersGroup,
          path: "cancellation",
        },
      ],
    },
    {
      id: "settings",
      name: t("settings"),
      leftIcon: IconSettings,
      path: "settings",
    },
    {
      id: "logout",
      name: t("logout"),
      leftIcon: IconLogout,
      path: "logout",
    },
  ];

  const [staticContent, setStaticContent] = useState(<></>);
  const removeStaticContent = useCallback(
    () => setStaticContent(<></>),
    [setStaticContent]
  );
  const isMobile = useIsMobile();

  return (
    <>
      <Stack direction={isMobile ? "column" : "row"}>
        {isMobile ? (
          <Menu options={defaultMenuOptions} />
        ) : (
          <VStack w="15%" bg="white" h="100%" px="4">
            <Box mt="4" mb="6">
              <img src="assets/svg/logo.svg" width="80%" height="20%" />
            </Box>
            <Menu options={defaultMenuOptions} />
          </VStack>
        )}

        <div style={{ pointerEvents: "none" }}>
          <Box
            position={"absolute"}
            h={{ base: "calc(100% - 60px)", lg: "100%" }}
            w={{ base: "100%", lg: "calc(100% - 15%)" }}
            zIndex={1}
          >
            {staticContent}
          </Box>
        </div>
        <Box
          h="100vh"
          bg="#F8FBFF"
          overflowY="auto"
          width="100%"
          maxW={isMobile ? "100%" : "calc(100% - 15%)"}
        >
          <Outlet context={{ setStaticContent, removeStaticContent }} />
        </Box>
      </Stack>
    </>
  );
};
type ContextType = {
  setStaticContent: (node: ReactNode) => void;
  removeStaticContent: () => void;
};

export function useCommonLayout() {
  const context = useOutletContext();
  if (!context) throw new Error("Must be inside a CommonLayout");
  return useOutletContext<ContextType>();
}
