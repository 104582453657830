import { EmptyButton, ModalTypes } from "@components/";
import { useMutation } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { defaultThumbnailUrl } from "@utils/mock/defaultThumbnailUrl";

import { deleteSongApi } from "api/deleteSong";
import { GET_ALBUMS_URL, getAlbumsApi } from "api/getAlbumsApi";
import { GET_SONGS_URL, getAllSongsApi } from "api/getAllSongsApi";
import { color } from "framer-motion";
import { t } from "i18next";
import { url } from "inspector";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "@utils/hooks";
import { useModal } from "@contexts/";
import { HStack, Box, Text, VStack, Image } from "native-base";

import { getCommonApi } from "api/getCommonApi";
import { GET_ALL_USERS } from "api/getUsersApi";
import {
  deleteUsersCancellationApi,
  getUsersCancellationApi,
  GET_USERS_CANCELLATION,
} from "api/getUsersCancellationApi";
import { createMembership, deleteMembership } from "api/memberships"
import { GET_USERS_ADMIN } from "api/paths";
import { UserForm } from "../components/UserForm";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { queryClient } from "@api/";
import { format, formatISO } from "date-fns";

export type FilterBy = "followers" | "admins" | "cancellation";

export const useUsersTable = (filterByDefault: FilterBy) => {
  const { t } = useTranslation({ keyPrefix: "commons" });
  const { showModal, hideModal } = useModal();
  const { mutateAsync: deleteUser } = useMutation(deleteUsersCancellationApi);
  const [filterBy, setFilterBy] = useState<FilterBy>(
    filterByDefault ?? "cancellation"
  );
  const url = useMemo(() => {
    const urls = {
      followers: GET_ALL_USERS,
      admins: GET_USERS_ADMIN,
      cancellation: GET_USERS_CANCELLATION,
    };
    return urls[filterBy];
  }, [filterBy]);
  const fetcher = useMemo(() => mapToTableData(getCommonApi), []);

  const changeFilter = useCallback(
    (filter: FilterBy) => setFilterBy(filter),
    [setFilterBy]
  );

  const remove = useCallback(
    (data: any) => {
      const runDelete = async () => {
        await deleteUser(data);
        await queryClient.invalidateQueries([GET_USERS_CANCELLATION]);
        hideModal();
      };

      showModal(ModalTypes.CONFIRM, {
        title: t("messages.deleteTitle"),
        description: t("messages.deleteDescription"),
        onConfirm: runDelete,
        onCancel: () => {
          hideModal();
        },
      });
    },
    [filterBy]
  );

  const openUserModal = (user: any) => {
    const editUserMembership = async (formValues: any) => {
      const userMembershipId = user?.membership?.id
      // If user has active subscription remove it
      if (userMembershipId) await deleteMembership({ membershipId: userMembershipId });
      // If premium is still selected create subscription with new params
      if (formValues.premium) {
        await createMembership({ userId: user.id, expiration: new Date(formValues.expiration).toISOString() });
      }

      await queryClient.invalidateQueries([GET_ALL_USERS]);
      hideModal();
    };

    const expirationDate = user?.membership?.expiration
    const defaultValues = {
      expiration: expirationDate ? new Date(expirationDate) : new Date(),
      premium: user.premium
    }

    showModal(ModalTypes.COMMON, {
      contentProps: { size: 'md', height: 'auto' },
      title: t("messages.management"),
      body: <UserForm onSubmit={editUserMembership} defaultValues={defaultValues} />
    });
  };

  const columns: ColumnDef<any>[] = useMemo(() => {
    const tables: any = {
      admins: [
        {
          accessorKey: "fullname",
          header: t("messages.fullName"),
          cell: (item: any) => {
            return (
              <Image
                source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
                alt="Post Image"
                resizeMode="cover"
                size="60px"
                rounded="xl"
                fallbackSource={defaultThumbnailUrl}
              />
            );
          },
          footer: (props: any) => props.column.id,
        },
        {
          accessorKey: "email",
          header: t("messages.email"),
          footer: (props: any) => props.column.id,
        },
      ],
      followers: [
        {
          accessorKey: "fullname",
          header: t("messages.fullName"),
          cell: (item: any) => {
            return (
              <Image
                source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
                alt="Post Image"
                resizeMode="cover"
                size="60px"
                rounded="xl"
                fallbackSource={defaultThumbnailUrl}
              />
            );
          },
          footer: (props: any) => props.column.id,
        },
        {
          accessorKey: "email",
          header: t("messages.email"),
          footer: (props: any) => props.column.id,
        },
        {
          accessorKey: "premium",
          header: t("messages.premium"),
          footer: (props: any) => props.column.id,
        },
        {
          accessorKey: "membership.expiration",
          header: t("messages.expiration"),
          cell: (item: any) => {
            const value = item.getValue();
            if (!value) return ''

            const date = new Date(value.toString());
            return format(date, 'dd/MM/yyyy')
          },
          footer: (props: any) => props.column.id,
        },
        {
          id: "action",
          header: t("messages.management"),
          cell: (item: any) => {
            // Don't allow override subscription for user used in-app purchase
            if (item?.row?.original?.membership?.provider === 'appstore') return null

            return (
              <HStack space="6">
                <EmptyButton onClick={() => openUserModal(item.row.original)}>
                  <IconEdit size={30} color="black" />
                </EmptyButton>
              </HStack>
            )
          },
        }
      ],
      cancellation: [
        {
          accessorKey: "fullname",
          header: t("messages.fullName"),
          cell: (item: any) => {
            return (
              <Image
                source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
                alt="Post Image"
                resizeMode="cover"
                size="60px"
                rounded="xl"
                fallbackSource={defaultThumbnailUrl}
              />
            );
          },
          footer: (props: any) => props.column.id,
        },
        {
          accessorKey: "email",
          header: t("messages.email"),
          footer: (props: any) => props.column.id,
        },
        {
          id: "1",
          cell: (item: any) => (
            <HStack space="6">
              <EmptyButton onClick={() => remove(item.row.original)}>
                <IconTrash size={30} color="black" />
              </EmptyButton>
            </HStack>
          ),
        },
      ],
    };

    return tables[filterBy];
  }, [filterBy]);

  useEffect(() => {
    if (filterByDefault) {
      changeFilter(filterByDefault);
    }
  }, [filterByDefault]);

  return {
    columns,
    fetcher,
    url,
  };
};
