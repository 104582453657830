import { getPostsApi, GET_POTST_URL } from "@api/";
import {
  ModalTypes,
  Post,
  StaggerButton,
  useCommonLayout,
  Skeleton,
} from "@components";
import { useAuth, useModal } from "@contexts";
import { IconDeviceMobile, IconLayoutDashboard } from "@tabler/icons-react";
import { SupportedFileTypes, useIsMobile } from "@utils/hooks";
import { usePaginatedQuery } from "@utils/hooks/usePaginatedQuery";
import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { getReelsApi, GET_REELS_URL } from "api/getReelsApi";
import { Box, Divider, HStack, Image, useToast, VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Reel } from "components/Reel";
import { useEntryActions } from "./hooks/useEntryActions";

export interface HomeProps extends InterfaceBoxProps {}

export const Home: FC<HomeProps> = ({ ...props }) => {
  const { setStaticContent } = useCommonLayout();
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const { isLoading: isSessionLoading } = useAuth();
  const { data, isLoading } = usePaginatedQuery<IPostResponse>(
    [GET_POTST_URL, `?sort=createdAt,desc`],
    getPostsApi,
    {
      enabled: !isSessionLoading,
    }
  );
  const { data: reels, isLoading: isLoadingReels } =
    usePaginatedQuery<IPostResponse>([GET_REELS_URL], getReelsApi, {
      enabled: !isSessionLoading,
    });

  const {
    remove: removeReel,
    edit: editReel,
    upload: uploadReel,
  } = useEntryActions("reel");
  const {
    remove: removePost,
    edit: editPost,
    upload: uploadPost,
  } = useEntryActions("post");

  const staggerOptions = useMemo(
    () => [
      {
        id: 'post',
        name: "Post",
        icon: <IconDeviceMobile size={26} color="white" />,
        onClick: () => uploadPost(),
      },
      {
        id: 'reel',
        name: "Reel",
        icon: <IconLayoutDashboard size={26} color="white" />,
        onClick: () => uploadReel(),
      },
    ],
    [uploadReel, uploadPost]
  );

  useEffect(() => {
    setStaticContent(
      <div style={{ pointerEvents: "all" }}>
        <Box
          position="absolute"
          right={isMobile ? "15px" : "30px"}
          bottom={isMobile ? "100px" : "30px"}
          zIndex={2}
        >
          <StaggerButton options={staggerOptions} />
        </Box>
      </div>
    );
    return () => setStaticContent(<></>);
  }, [setStaticContent]);

  return (
    <VStack>
      <HStack space="4" m="2" overflowX="scroll">
        {reels?.content?.map((reel, index) => (
          <Reel
            key={reel?.id || `reel-skeleton-${index}`}
            data={reel}
            isLoading={false}
            onEdit={editReel}
            onDelete={removeReel}
          />
        ))}
      </HStack>

      <VStack overflowY="hidden" alignItems="center">
        <VStack space="2">
          {data?.content?.map((post, index) => (
            <Post
              key={post?.id || `post-skeleton-${index}`}
              data={post}
              isLoading={isLoading}
              onEdit={editPost}
              onDelete={removePost}
            />
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};
