import React from "react";
import { Chart } from "react-google-charts";
import { useTheme } from "native-base";
import { ICommonChartProps } from "@utilsmodels/Chart";

const mock = [
  ["Year", "Sales", "Expenses", "Profit"],
  ["2014", 1000, 400, 200],
  ["2015", 1170, 460, 250],
  ["2016", 660, 1120, 300],
  ["2017", 1030, 540, 350],
];
export function BarChart({ data = mock, options }: ICommonChartProps) {
  const theme = useTheme();

  const newOptions = options ?? {
    colors: [theme.colors.primary[400], theme.colors.primary[600]],
    chart: {
      // title: "Company Performance",
      // subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };

  return (
    <Chart
      options={newOptions}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
          },
        },
      ]}
      chartType="Bar"
      width="100%"
      height="200px"
      data={data}
    />
  );
}
