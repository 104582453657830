// import { useNavigation } from "@react-navigation/native";

import { getVideosApi, VIDEOS_URL, queryClient } from "@api";
import {
  EmptyButton,
  SectionHeader,
  Table,
  Button,
  ModalTypes,
  Animated,
} from "@components/";
import {
  IconEdit,
  IconLivePhoto,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { Person } from "components/Table/fetchData";
import { useModal } from "contexts/Modal";
import { HStack, Text, VStack, Checkbox, Image, useToast } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useCallback, useState } from "react";
import { AddVideoForm } from "./components/AddVideoForm";
import { defaultThumbnailUrl } from "@utils/mock/defaultThumbnailUrl";
import { useMutation } from "@tanstack/react-query";
import { postMediaApi } from "api/postMediaApi";
import { useNavigate } from "react-router-dom";
import { deleteMediaApi } from "api/deleteMediaApi";
import { GET_SONGS_URL } from "api/getAllSongsApi";
import { putVideoApi } from "api/putVideoApi";

export interface VideosProps extends InterfaceBoxProps {}

const notification = () => {
  return {
    title: "New album release",
    url: "https://www.youtube.com/watch?v=WfuyKMaQQbk",
    premium: false,
    // "https://www.youtube.com/watch?v=WfuyKMaQQbk"
    // "https://www.youtube.com/watch?v=uF373Iynpsc"
  };
};
async function fetchNotifications(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: new Array(4).fill(notification()),
    pageCount: 0,
  };
}

export const Videos: FC<VideosProps> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "commons" });
  const { t: tVideos } = useTranslation({ keyPrefix: "videos" });
  const { showModal, hideModal } = useModal();
  const [hasLiveVideo, setHasLiveVideo] = useState<null | any>(null);
  // const [itemSelected, setItemSelected] = useState<null | any>(null);
  const { mutateAsync: uploadVideoApi } = useMutation(postMediaApi);
  const { mutateAsync: editVideoApi } = useMutation(putVideoApi);
  const navigate = useNavigate();
  const { show: showToast } = useToast();
  const { t: tCommon } = useTranslation({ keyPrefix: "commons" });
  const { mutateAsync: deleteMedia } = useMutation(deleteMediaApi);

  const removeVideo = useCallback((video: any) => {
    const runDelete = async () => {
      await deleteMedia({ mediaId: video.id });
      await queryClient.invalidateQueries([VIDEOS_URL]);
      hideModal();
    };

    showModal(ModalTypes.CONFIRM, {
      title: tCommon("messages.deleteTitle"),
      description: tCommon("messages.deleteDescription"),
      onConfirm: runDelete,
      onCancel: () => {
        hideModal();
      },
    });
  }, []);

  const editVideo = useCallback((video: any) => {
    openEditModal(video);
  }, []);

  const searchForLiveVideo = (data: any) =>
    setHasLiveVideo(
      data.content?.length
        ? data.content.find((data: any) => data.live)
          ? true
          : false
        : false
    );

  const uploadVideo = async (form: any, edit = false) => {
    edit ? await editVideoApi(form) : await uploadVideoApi(form);

    showToast({
      duration: null,
      title: `${t("messages.successfully")} ${t("messages.updated")}`,
      variant: "left-accent",
    });
    queryClient.invalidateQueries([VIDEOS_URL]);
    hideModal();
  };

  const openUploadModal = () => {
    showModal(ModalTypes.COMMON, {
      title: "Upload video",
      body: <AddVideoForm onSubmit={uploadVideo} options={{ hasLiveVideo }} />,
    });
  };

  const openEditModal = (data: any) => {
    showModal(ModalTypes.COMMON, {
      title: "Edit video",
      body: (
        <AddVideoForm
          defaultValues={{ ...data, thumbnail: null }}
          options={{ hasLiveVideo, edit: true }}
          onSubmit={(data) =>uploadVideo(data,true)}
        />
      ),
    });
  };

  const columns: ColumnDef<Person>[] = [
    {
      accessorKey: "thumbnail",
      header: t("messages.image"),
      cell: (item) => {
        return (
          <Image
            source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
            alt="Post Image"
            resizeMode="cover"
            size="60px"
            rounded="xl"
            fallbackSource={defaultThumbnailUrl}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "title",
      header: t("messages.title"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "path",
      header: t("messages.url"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "live",
      header: t("messages.live"),
      cell: (item) =>
        item.getValue() ? (
          <Animated.Box
            initial={{ scale: 1 }}
            animate={{
              scale: 1.5,
              transition: {
                duration: 1,
                type: "ease",
                repeatType: "mirror",
                repeat: Infinity,
              },
            }}
          >
            <IconLivePhoto color="#e73636" />
          </Animated.Box>
        ) : (
          <></>
        ),
    },
    {
      accessorKey: "edit",
      header: "",
      cell: (item) => (
        <EmptyButton onClick={() => editVideo(item.row.original)}>
          <IconEdit size={30} color="black" />
        </EmptyButton>
      ),
    },
    {
      accessorKey: "delete",
      header: "",
      cell: (item) => (
        <EmptyButton onClick={() => removeVideo(item.row.original)}>
          <IconTrash size={30} color="black" />
        </EmptyButton>
      ),
    },
  ];

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={tVideos("title")} />
        <HStack space="2">
          <Button
            py="3"
            px="6"
            bg="primary.400"
            rounded="xl"
            onClick={openUploadModal}
          >
            <HStack alignItems="center" space="2">
              <IconPlus size={16} color="white" />
              <Text color="white">{t("messages.upload")}</Text>
            </HStack>
          </Button>
        </HStack>
      </HStack>
      <Table
        columns={columns}
        fetcher={mapToTableData(getVideosApi)}
        url={VIDEOS_URL}
        onDataSuccess={searchForLiveVideo}
      />
    </VStack>
  );
};
