import {
  CommonSection,
  EmptyButton,
  RadioField,
  SectionHeader,
  TextField,
  TextAreaField,
} from "@components/";
import { IconTrash, IconPlus, IconEdit, IconPhone, IconBrandApple, IconBrandAndroid, IconDeviceMobileQuestion, IconDeviceMobile, IconCalendar, IconUserExclamation, IconUserOff, IconQuestionMark } from "@tabler/icons-react";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import {
  VStack,
  HStack,
  Image,
  Text,
  Switch,
  Radio,
  Box,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GET_USERS_ABUSERS } from "api/getUsersCancellationApi";
import { getCommonApi } from "api/getCommonApi";
import { Button, Popconfirm, Table } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { deletePostApi } from "@api*";
import { blockOrWarnUser } from "api/blockAndWarnUsersApi";
import { queryClient } from "@api";

export interface AbusersProps extends InterfaceBoxProps {}

export const Abusers: FC<AbusersProps> = ({ ...props }) => {
  const { control } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName: any = useMemo(
    () => location.pathname.split("/")[1],
    [location]
  );
  const { t } = useTranslation({ keyPrefix: "commons" });

  const { data : potentialAbusers, isLoading } = useQuery<any>([GET_USERS_ABUSERS], getCommonApi, {
    refetchOnWindowFocus: false,
    initialData : []
  });

  const {mutateAsync:doBlockOrWarnUser, isLoading : isBlockingUser} = useMutation(blockOrWarnUser, {onSuccess:async ()=>{
    await queryClient.invalidateQueries([GET_USERS_ABUSERS]);

  }});

  
    const columns = [
      {
        title: t("messages.fullName"),
        dataIndex: 'fullName',
        key: 'fullName',
        render : (fullName:string, record:any) => {
            if(fullName) { 
              return fullName;
            } else {
              return record.userID;
            }
        }
      },
      Table.EXPAND_COLUMN,
      {
        title: t("messages.abusers.deviceCount"),
        dataIndex: 'deviceCount',
        key: 'deviceCount',
      },
      {
        dataIndex : 'userID',
        render : (userId:string) => {
          return (<HStack space={2}>
              <Popconfirm
                title={t("messages.abusers.warn.title")}
                description={t("messages.abusers.warn.description")}
                onConfirm={()=> {
                  doBlockOrWarnUser({userId:userId, action: 'warn'})
                }}
                okText={t("messages.abusers.warn.yes")}
                cancelText={t("messages.abusers.warn.no")}
                icon={<IconQuestionMark style={{ color: 'red' }} />}>
                <Button danger><HStack alignItems={'center'} space={2}><IconUserExclamation size={16}/> {t('messages.abusers.warn.button')}</HStack></Button>
            </Popconfirm>
            <Popconfirm
              title={t("messages.abusers.block.title")}
              description={t("messages.abusers.block.description")}
              okText={t("messages.abusers.block.yes")}
              cancelText={t("messages.abusers.block.no")}

              onConfirm={()=> {
                doBlockOrWarnUser({userId:userId, action: 'block'})
              }}
              icon={<IconQuestionMark style={{ color: 'red' }} />}>
              <Button type="primary" danger>
                <HStack alignItems={'center'} space={2}><IconUserOff size={16}/> {t('messages.abusers.block.button')}</HStack>
              </Button>
            </Popconfirm>
          </HStack>);
        }
      }
    ];

    const subColumns = [
      {
        title: <IconDeviceMobileQuestion />,
        dataIndex: 'deviceModel',
        key: 'deviceModel',
        render : (deviceModel:any) => {
            let devModel = deviceModel.split("__");
            return (<HStack>{devModel[1] == 'IOS' ? <IconBrandApple /> : <IconBrandAndroid />}<span>({devModel[2]})</span></HStack>)              
        }
      },
      {
        title: <IconDeviceMobile />,
        dataIndex: 'deviceModel',
        render  : (deviceModel:any) => {
          let devModel = deviceModel.split("__");
          return devModel[0]              
        },
        key: 'id',
      },
      {
        title: <IconCalendar />,
        dataIndex: 'updatedAt',
        render  : (updatedAt:string) => {
          return format(new Date(updatedAt), 'dd/MM/yyyy HH:mm');      
        },
        key: 'id',
      },
    ];

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={t("messages.abusers.list")} />
      </HStack>
      <Outlet />
      <Table rowKey="userID" pagination={false} loading={isLoading} dataSource={potentialAbusers} columns={columns} expandable={{
      expandedRowRender: (record:any) => <Table size="small" pagination={false} dataSource={record.devices} columns={subColumns}/>,
    }}/>
      </VStack>
  );
};
