import React, {FC, useEffect, useState} from "react";
import { addMonths, format, isDate, isValid } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  Box,
  VStack,
  useDisclose,
  useTheme,
  IInputProps,
  Button,
} from "native-base";
import { IconCalendar } from "@tabler/icons-react";
import { EmptyButton, TextField } from "@components/";
import { commonDateFormat } from "@utils/helpers/commonDateFormat";
import { useFloating, FloatingPortal } from '@floating-ui/react';

export interface DatePickerFieldProps extends IInputProps {}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const theme = useTheme();
  const { refs, floatingStyles } = useFloating({ placement: "bottom-start" });
  const { isOpen, onOpen, onClose } = useDisclose();
  const [date, setDate] = useState<string>(commonDateFormat(value));

  const select = (day: Date) => {
    setDate(commonDateFormat(day));
    onClose();
  };

  useEffect(() => {
    onChange(date);
  }, [date, onChange]);

  // @ts-ignore
  return (
    <VStack ref={refs.setReference}>
      <TextField
        value={date}
        rightElement={
          <EmptyButton
            w="40px"
            h="47px"
            onClick={() => {
              if (isOpen) {
                onClose();
              } else {
                onOpen();
              }
            }}
          >
            <Box>
              <IconCalendar size={20} color="black" />
            </Box>
          </EmptyButton>
        }
        {...rest}
      />
      {isOpen && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles}>
            <Box
              bg="white"
              fontFamily="Arial"
              shadow={2}
              rounded="3xl"
            >
              <DayPicker
                mode="single"
                modifiersStyles={{
                  today: { backgroundColor: theme.colors.primary[100] },
                  selected: { backgroundColor: theme.colors.primary[400] },
                }}
                selected={new Date(date)}
                onSelect={(day) => day && select(day)}
              />
            </Box>
          </div>
        </FloatingPortal>
      )}
    </VStack>
  );
};
