import {
  SectionHeader,
  CommonSection,
  SectionStepper,
  SummarySection,
} from "@components";
import { FormWizardDef, useAuth, useFormWizard } from "@contexts/";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { withFormField, withFormWizard } from "@utils/hoc";
import { GET_ALBUMS_URL, getAlbumsApi } from "api/getAlbumsApi";
import { WizardFormRender } from "components/WizardFormRender";
import { HStack, useToast, VStack, Box, Divider, AspectRatio, Image, Center, Stack, Heading, Text } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { postSongApi } from "api/postSongApi";
import { postMediaApi } from "api/postMediaApi";
import { IFileDef } from "@utilsmodels/FileItem";
import {
  generateSongToAlbumUrl,
  postSongToAlbumApi,
} from "api/postSongToAlbumApi";
import { useLocation, useNavigate } from "react-router-dom";
import { commonDateFormat } from "@utils/helpers/commonDateFormat";
import { putSongApi } from "api/putSongApi";
import { IconEdit, IconKey } from "@tabler/icons-react";
import { getSongAlbumsApi } from "api/getSongAlbumsApi";
import AlbumsCards from "./AlbumsCards";
import { deleteSongFromAlbum } from "api/deleteSongApi";

export interface UploadSongProps extends InterfaceBoxProps {}

export const UploadSong: FC<UploadSongProps> = withFormWizard(
  ({ ...props }) => {
    const {
      currentForm,
      forms,
      formsValues,
      nextForm,
      prevForm,
      submit,
      changeForm,
      formState,
    } = useFormWizard();
    const navigate = useNavigate();
    const { show: showToast } = useToast();
    const { session } = useAuth();
    const stepNames = forms.map((form) => form.name);
    const isFirst = currentForm.name === stepNames[0];
    const isLast = currentForm.name === stepNames[stepNames.length - 1];
    const sections = forms.map(({ name, fields }) => ({
      title: name,
      items: fields.map(({ form: { defaultValue }, label }) => ({
        name: label.replace("*", ""),
        value: Array.isArray(defaultValue)
          ? defaultValue.map((file) => file.file.name ?? "").join(",")
          : defaultValue,
      })),
      onClick: (formTitle: string) => changeForm(formTitle),
    }));
    const location = useLocation();
    const defaultValues = location?.state?.data;
    const [selectedAlbums, setSelectedAlbums] = useState<any[]>([]);
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const { t: tCommons } = useTranslation({ keyPrefix: "commons" });
    const { mutateAsync: uploadAlbumApi, isLoading: isLoadingAlbum } =
      useMutation(postSongToAlbumApi);
    const { mutateAsync: uploadMediaApi, isLoading: isLoadingMedia } =
      useMutation(postMediaApi);
    const { mutateAsync: uploadSongApi, isLoading: isLoadingSong } =
      useMutation(postSongApi);
    const { mutateAsync: updateSong, isLoading: isLoadingUpdateSong } =
      useMutation(putSongApi);
    const isLoading = useMemo(
      () =>
        isLoadingAlbum ||
        isLoadingSong ||
        isLoadingMedia ||
        isLoadingUpdateSong,
      [isLoadingAlbum, isLoadingSong, isLoadingMedia, isLoadingUpdateSong]
    );

    const { mutateAsync: removeSongFromAlbum } = useMutation(deleteSongFromAlbum);
  

    const uploadSong = useCallback(async () => {
      const mediaForm: any = new FormData();
      const songForm: any = new FormData();
      const songValues = formsValues.find(({ form }) => form.id === "song");

      if (!songValues?.values) return;

      songValues?.values.file.map((file: IFileDef) =>
        mediaForm.append("files", file.file, file.file.name)
      );

      mediaForm.append("title", songValues?.values.title);
      mediaForm.append("description", songValues?.values.title);
      mediaForm.append("shouldPost", false);
      mediaForm.append("userID", session?.user.id);
      
      const mediaResponse = await uploadMediaApi(mediaForm);

      Object.keys(songValues?.values).forEach((key) => {
        if (
          songValues?.values[key] !== "" &&
          key !== "file" &&
          key !== "thumbnail"
        ) {
          songForm.append(key, songValues?.values[key]);
        } else if (key == "thumbnail" && songValues?.values["thumbnail"]) {
          songValues?.values?.thumbnail?.forEach((file: IFileDef) =>
            songForm.append("thumbnail", file.file, file.file.name)
          );
        }
      });

      songForm.append("audioMediaId", mediaResponse.audios[0].id);
      songForm.append("premium", songForm.get("premium") ? true : false);

      const songResponse: any = await uploadSongApi(songForm);

      for (const albumToAdd of selectedAlbums) {
        uploadAlbumApi(generateSongToAlbumUrl(albumToAdd.id, songResponse.id));
      }

      showToast({
        duration: 500,
        // id: ERROR_TOAST_ID,
        title: tCommons("messages.successfully"),
        variant: "left-accent",
        description: tCommons("messages.uploaded"),
      });
      navigate("/music");
    }, [uploadAlbumApi, uploadMediaApi, formsValues]);

    const update = useCallback(async () => {
      const songForm: any = new FormData();
      const songValues: any = formsValues.find(
        ({ form }) => form.id === "song"
      );

      Object.keys(songValues?.values).forEach((key) => {
        if (
          songValues?.values[key] !== "" &&
          key !== "file" &&
          key !== "thumbnail" &&
          key !== "lyrics"
        ) {
          songForm.append(key, songValues?.values[key]);
        }
      });

      songForm.append("id", defaultValues.id);

      await updateSong(songForm);

      let removed:any[] = [];
      let added:any[] = [];

      selectedAlbums.forEach((selected)=> {
        let found = songAlbums.find((album:any)=> {return album.id===selected.id});
        if(!found){
          added.push(selected.id);
        }
      });

      songAlbums.forEach((existingAlbum:any)=> {
        let found = selectedAlbums.find((album:any)=> {return album.id===existingAlbum.id});
        if(!found){
          removed.push(existingAlbum.id);
        }
      });

      for (const albumToAdd of added) {
        uploadAlbumApi(generateSongToAlbumUrl(albumToAdd, defaultValues.id));
      }

      for (const albumToRemove of removed) {
        removeSongFromAlbum({albumId: albumToRemove, songId:defaultValues.id});
      }

      showToast({
        duration: null,
        title: tCommons("messages.successfully"),
        variant: "left-accent",
        description: tCommons("messages.updated"),
      });
      navigate("/music");
    }, [uploadAlbumApi, uploadMediaApi, formsValues]);

    const successHandler = useCallback(
      (data: any) => {
        if (isLast) {
          if (defaultValues) {
            update();
          } else {
            uploadSong();
          }
        } else {
          nextForm();
        }
      },
      [isLast, uploadSong, nextForm]
    );

    const { data : albums } = useQuery([GET_ALBUMS_URL], getAlbumsApi, {
      refetchOnWindowFocus: false,
    });

    const { data : songAlbums, mutateAsync: getSongAlbums, isLoading: isLoadingAlbums } =
      useMutation(getSongAlbumsApi);


    useEffect(()=> {
      if(defaultValues) {
        getSongAlbums(defaultValues.id);
      }
    },[defaultValues]);


    useEffect(()=> {
      if(songAlbums) {
        setSelectedAlbums([...songAlbums]);
      }
    },[songAlbums]);

    const addToSelectedAlbums = (album:any)=> {
      setSelectedAlbums([...selectedAlbums, album]);
    }

    const removeFromSelectedAlbums = (album:any)=> {
      let newAlbums: any[] = [];
      selectedAlbums.forEach((selected)=> {
        if(selected.id != album.id) {
          newAlbums.push(selected);
        }
      });
      setSelectedAlbums(newAlbums);
    }


    return (
      <VStack py="8" px="6" space="6" maxW="1500px">
        <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
          <SectionHeader title={location?.state?.data ? t("title.new") : t('title.edit')} />
        </HStack>

        <SectionStepper steps={stepNames} currentStep={currentForm.name} />

        <CommonSection
          title={currentForm.name}
          backButton={isFirst ? t("buttons.cancel") : t("buttons.previous")}
          nextButton={isLast ? t("buttons.publish") : t("buttons.next")}
          nextButtonProps={{ isLoading: isLoading }}
          onNextClick={() =>
            submit({
              onSuccess: successHandler,
            })
          }
          onBackClick={() => (isFirst ? navigate("/music") : prevForm())}
        >
          {
            currentForm.id == "album" && <>
              <HStack space={6}>
               <AlbumsCards albums={selectedAlbums} removeFromSelected={removeFromSelectedAlbums}></AlbumsCards>
               <AlbumsCards  albums={albums?.albums?.content} exclude={selectedAlbums} addToSelected={addToSelectedAlbums}></AlbumsCards>
               </HStack>
            </>
          }

          {isLast ? (
            <SummarySection
              sections={sections.filter(
                ({ title }) => title !== t("summary.title")
              )}
            />
          ) : (
            <WizardFormRender name={currentForm.name} />
          )}
        </CommonSection>
      </VStack>
    );
  },
  () => {
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const { t: tCommons } = useTranslation({ keyPrefix: "commons" });
    const location = useLocation();
    const defaultValues = location?.state?.data;

    const forms: FormWizardDef[] = useMemo(
      () => [
        {
          id : "song",
          name: t("songs.title"),
          order: 1,
          fields: [
            {
              type: "text",
              label: `${t("songs.fields.title.label")}*`,
              form: {
                name: "title",
                defaultValue: defaultValues?.title,
                rules: { required: "Required field" },
              },
            },
            {
              type: "text",
              label: `${t("songs.fields.artist.label")}*`,
              form: {
                name: "artist",
                defaultValue: defaultValues?.artist ?? "AniLorak",
                rules: { required: "Required field" },
              },
            },
            {
              type: "text",
              label: `${t("songs.fields.additionalArtist.label")}`,
              form: {
                name: "additionalArtists",
                defaultValue: defaultValues?.artist,
              },
            },
            {
              type: "options",
              label: `${t("songs.fields.genre.label")}*`,
              options: [
                { value: "Rock", label: "Rock" },
                { value: "Pop", label: "Pop" },
              ],
              form: {
                name: "genre",
                defaultValue: defaultValues?.genre ?? "Pop",
                rules: { required: "Required field" },
              },
            },
            {
              type: "date",
              label: `${t("album.fields.date.label")}*`,
              form: {
                name: "releasedAt",
                defaultValue: commonDateFormat(
                  new Date(defaultValues?.releasedAt ?? Date.now())
                ),
                rules: { required: "Required field" },
              },
            },
            {
              type: "switch",
              label: `${tCommons("messages.premium")}`,
              form: {
                name: "premium",
                defaultValue: defaultValues?.premium,
              },
              options:[
                {
                  label: tCommons("messages.yes"),
                  value: true,
                  Icon: IconEdit,
                },
                {
                  label: tCommons("messages.no"),
                  value: false,
                  Icon: IconKey,
                },
              ]
            },
            {
              type: "file",
              supportedFile: SupportedFileTypes.ALL,
              label: `${t("songs.fields.lyrics.label")}`,
              isDisabled: defaultValues?.audioMediaId ? true : false,
              form: {
                name: "lyrics",
                defaultValue: "",
              },
            },
            {
              type: "file",
              supportedFile: SupportedFileTypes.IMAGE,
              label: `${t("songs.fields.thumbnail.label")}`,
              isDisabled: defaultValues?.audioMediaId ? true : false,
              form: {
                name: "thumbnail",
                defaultValue: "",
              },
            },
            {
              type: "file",
              supportedFile: SupportedFileTypes.MUSIC,
              label: `${t("songs.fields.file.label")}*`,
              isDisabled: defaultValues?.audioMediaId ? true : false,
              form: {
                name: "file",
                defaultValue: "",
                ...(defaultValues
                  ? {}
                  : { rules: { required: "Required field" } }),
              },
            },
          ],
        },
        {
          id: 'album',
          name: "Album",
          order: 2,
          fields: [],
        },
        {
          id: 'summary',
          name: t("summary.title"),
          order: 3,
          fields: [],
        },
      ],
      []
    );

    return forms;
  }
);
