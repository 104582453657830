import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FieldValues,
  SubmitErrorHandler,
  useController,
  UseControllerProps,
  useForm,
  UseFormHandleSubmit,
  UseFormReturn,
} from "react-hook-form";

export type FormWizardFieldType =
  | "text"
  | "number"
  | "file"
  | "textarea"
  | "radio"
  | "switch"
  | "options"
  | "date";

export interface FormWizardField {
  type: FormWizardFieldType;
  form: UseControllerProps;
  [k: string]: any;
}

export type FormWizardDef = {
  id: string;
  name: string;
  hidden? : boolean;
  fields: FormWizardField[];
  order: number;
  customData?: any;
};

interface FormWizardContextType {
  formState: Partial<UseFormReturn<any, any>>;
  formsValues: { form: FormWizardDef; values: { [k: string]: any } }[];
  currentForm: FormWizardDef;
  forms: FormWizardDef[];
  submit: (submitHandler?: SubmitHandler) => void;
  changeForm: (formName: string) => void;
  nextForm: () => void;
  prevForm: () => void;
}

interface FormWizardProviderProps {
  forms: FormWizardDef[];
  children: ReactNode | ReactNode[];
}

interface SubmitHandler {
  onSuccess?: (data: FieldValues) => void;
  onInvalid?: (error: any) => void;
}

export const FormWizardContext = createContext<FormWizardContextType>({
  currentForm: {
    id: "",
    name: "",
    order: 0,
    fields: [],
    customData: null,
  },
  forms: [],
  formsValues: [],
  formState: {},
  submit: () => {},
  changeForm: () => {},
  nextForm: () => {},
  prevForm: () => {},
});

const defaultValues = (fields: FormWizardField[]) =>
  fields.reduce((obj: any, field) => {
    obj[field.form.name] = field.form.defaultValue ?? "";
    return obj;
  }, {});

export const FormWizardProvider: React.FC<FormWizardProviderProps> = ({
  forms: initialForms,
  children,
}) => {
  const [forms, setForms] = useState(() =>
    initialForms.sort((a, b) => a.order - b.order)
  );
  const initialForm = forms[0] ?? {};
  const [currentForm, setCurrentForm] = useState(initialForm);
  const [formsValues, setFormsValues] = useState<any>([]);

  const formState = useForm({
    values: defaultValues(currentForm.fields),
    defaultValues: defaultValues(currentForm.fields),
  });

  const changeForm = useCallback(
    (formName: string) => {
      const newForm = forms.find(({ name }) => name === formName);
      if (newForm === undefined) throw new Error("Form name not found");

      storeFromData();
      setCurrentForm(newForm);
    },
    [forms, setCurrentForm]
  );

  const storeFromData = useCallback(() => {
    const newForm = {
      ...currentForm,
      fields: currentForm.fields.map((field) => {
        const defaultValue = formState.watch(field.form.name);

        return {
          ...field,
          form: {
            ...field.form,
            defaultValue,
          },
        };
      }),
    };

    const newForms = forms.map((form) => {
      if (form.name === newForm.name) {
        return newForm;
      }
      return form;
    });

    // store forms values
    const newFormsValues = [
      ...formsValues,
      { form: newForm, values: formState.watch() },
    ];

    formState.unregister(currentForm.fields.map(({ form: { name } }) => name));
    setForms(newForms);
    setFormsValues(newFormsValues);
  }, [currentForm, setForms, forms, formsValues, setFormsValues, currentForm]);

  const nextForm = useCallback(() => {
    const newFormPosition =
      currentForm.order + 1 <= forms.length
        ? currentForm.order + 1
        : currentForm.order;
    const newForm = forms.find(({ order }) => order === newFormPosition);
    if (newForm === undefined) throw new Error("Form order not found");

    changeForm(newForm.name);
  }, [setCurrentForm, forms, currentForm]);

  const prevForm = useCallback(() => {
    const newFormPosition =
      currentForm.order - 1 > 0 ? currentForm.order - 1 : currentForm.order;
    const newForm = forms.find(({ order }) => order === newFormPosition);
    if (newForm === undefined) throw new Error("Form order not found");

    changeForm(newForm.name);
  }, [setCurrentForm, forms, currentForm]);

  const submit = useCallback(
    async (submitHandler?: SubmitHandler) => {
      await formState.handleSubmit(
        submitHandler?.onSuccess ? submitHandler.onSuccess : () => {},
        submitHandler?.onInvalid
      )();
    },
    [formState]
  );

  const value = useMemo(
    () => ({
      forms,
      currentForm,
      formState,
      formsValues,
      changeForm,
      submit,
      nextForm,
      prevForm,
    }),
    [
      formState,
      currentForm,
      forms,
      submit,
      changeForm,
      nextForm,
      prevForm,
      formsValues,
    ]
  );

  return (
    <FormWizardContext.Provider value={value}>
      {children}
    </FormWizardContext.Provider>
  );
};

export const useFormWizard = () => {
  const context = useContext(FormWizardContext);
  if (context === undefined) {
    throw new Error("useFormWizard must be inside a FormWizardProvider");
  }
  return context;
};
