import { AxiosResponse } from "axios";
import { networkClient } from "./networkClient";
import { VIDEOS_URL } from "./paths";

export interface IUploadMediaPayload {
  title: string;
  description: string;
  userID: string;
  latitude: string;
  longitude: string;
  shouldPost: boolean;
  premium: boolean;
  files: File[];
  url?: string;
}

export interface IUploadMediaResponse {
  id: string;
  audios: { id: string }[];
  photos: { id: string }[];
  videos: { id: string }[];
  createdAt: string;
  updatedAt: string;
}

export const putVideoApi = async (form: any) => {
  const { data } = await networkClient.put<
    IUploadMediaPayload,
    AxiosResponse<IUploadMediaResponse>
  >(`${VIDEOS_URL}/${form.get("id")}`, form, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
