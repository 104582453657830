import { Box, Text, VStack, useTheme } from "native-base";
import { Button } from "@components";
import { IconSquareRoundedX, IconUpload } from "@tabler/icons-react";
import { IFileDef } from "@utilsmodels/FileItem";
import { formatBlobSize } from "@utils/helpers";
import { FileThumbnail } from "components/FileThumbnail";

export interface FileItemProps {
  file: IFileDef;
  disabled: boolean;
  onRemove: (file: File) => void;
  onAddThubmnail?: () => void;
}

export function FileItem({
  file,
  disabled,
  onRemove,
  onAddThubmnail,
}: FileItemProps) {
  const theme = useTheme();

  return (
    <Box>
      <Box position="absolute" right="-2%" top="0%" zIndex={2}>
        {!disabled ? (
          <Button
            onClick={() => onRemove(file.file)}
            bg="transparent"
            p="0"
            _hover={{ bg: "transparent" }}
            _pressed={{ bg: "transparent" }}
          >
            <IconSquareRoundedX size={26} color={theme.colors.primary[300]} />
          </Button>
        ) : (
          <></>
        )}
      </Box>
      <VStack
        width="300px"
        bg="light.100"
        rounded="sm"
        // p="2"
        // alignItems="center"
        space="2"
      >
        <Box bg="white">
          <FileThumbnail file={file.file} width="300px" height={262} />
        </Box>
        {
          file.file.name && <VStack alignItems="center">
          <Text bold color="text.500" paddingLeft={10} paddingRight={10}>
            {file.file.name ? (file.file.name.length > 50 ?
              `${file.file.name.substring(0, 50)}...` : file.file.name) : ''
            }
          </Text>
          {!disabled ? (
            <Text fontWeight="light" color="text.500">
              {formatBlobSize(file.blob.size)}
            </Text>
          ) : (
            <></>
          )}
        </VStack>
        }
        
      </VStack>
    </Box>
  );
}
