import {
  SectionHeader,
  CommonSection,
  SectionStepper,
  SummarySection,
  Button,
  SearchField,
} from "@components";
import { FormWizardProvider, useFormWizard } from "@contexts/";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { withFormWizard } from "@utils/hoc";
import { WizardFormRender } from "components/WizardFormRender";
import { t } from "i18next";
import { Heading, HStack, useToast, VStack, Actionsheet, useDisclose, Text, Box, CheckCircleIcon } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postAlbumApi } from "api/postAlbumApi";
import { IFileDef } from "@utils/models/FileItem";
import { formatISO } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { commonDateFormat } from "@utils/helpers/commonDateFormat";
import { putAlbumApi } from "api/putAlbumApi";
import { getAlbumSongsApi } from "api/getAlbumSongs";
import { getAllSongsApi, getSongsSearchApi } from "api/getAllSongsApi";
import SongsCards from "./SongsCards";
import { IconPlus } from "@tabler/icons-react";
import { generateSongToAlbumAndIndexUrl, generateSongToAlbumUrl, postSongToAlbumApi } from "api/postSongToAlbumApi";
import { deleteSongFromAlbum } from "api/deleteSongApi";
import fi from "date-fns/esm/locale/fi/index.js";

export interface UploadAlbumProps extends InterfaceBoxProps {}

export const UploadAlbum: FC<UploadAlbumProps> = withFormWizard(
  ({ ...props }) => {
    const {
      currentForm,
      forms,
      nextForm,
      prevForm,
      submit,
      changeForm,
      formsValues,
      formState,
    } = useFormWizard();
    const navigate = useNavigate();
    const { show: showToast } = useToast();
    const stepNames = forms.map((form) => form.name);
    const isFirst = currentForm.name === stepNames[0];
    const isLast = currentForm.name === stepNames[stepNames.length - 1];
    const sections = forms.map(({ name, fields }) => ({
      title: name,
      items: fields.map(({ form: { defaultValue }, label }) => ({
        name: label.replace("*", ""),
        value: Array.isArray(defaultValue)
          ? defaultValue.map((file) => file.file.name ?? "").join(",")
          : defaultValue,
      })),
      onClick: (formTitle: string) => changeForm(formTitle),
    }));
    const location = useLocation();
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const { t: tCommons } = useTranslation({ keyPrefix: "commons" });
    const { mutateAsync: uploadAlbumApi, isLoading } =
      useMutation(postAlbumApi);
    
    const { mutateAsync: uploadAlbumSongApi, isLoading: isLoadingAlbum } =
      useMutation(postSongToAlbumApi);

    const { mutateAsync: updateAlbum, isLoading: isLoadingUpdateSong } =
      useMutation(putAlbumApi);
    const [formAlbum, setAlbumForm] = useState<any>({});
    const [selectedSongs, setSelectedSongs] = useState<any[]>([]);
    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose();
    const defaultValues = location?.state?.data;

    const { data : albumSongs, mutateAsync: getAlbumSongs, isLoading: isLoadingAlbumSongs } =
    useMutation(getAlbumSongsApi);

    const { data : songs, mutateAsync: getSongs, isLoading: isLoadingSongs } =
    useMutation(getSongsSearchApi);

    const { mutateAsync: removeSongFromAlbum } = useMutation(deleteSongFromAlbum);


    useEffect(()=> {
      if(defaultValues){
        getAlbumSongs(defaultValues.id);
      }
    },[defaultValues]);

    useEffect(()=> {
      if(albumSongs&&albumSongs.length>0){
        setSelectedSongs([...albumSongs]);
      }
    }, [albumSongs])

    const uploadAlbum = async () => {
      const newForm: any = new FormData();

      Object.keys(formAlbum).forEach((key) => {
        if (key === "thumbnail") {
          formAlbum.thumbnail.map((file: IFileDef) =>
            newForm.append("thumbnail", file.file, file.file.name)
          );
          return;
        }
        newForm.append(key, formAlbum[key]);
      });

      let newAlbum = await uploadAlbumApi(newForm);
      if(newAlbum&&newAlbum.id&&selectedSongs&&selectedSongs.length>0) {
        let i = 0;
        for (const songToAdd of selectedSongs) {
          uploadAlbumSongApi(generateSongToAlbumAndIndexUrl(newAlbum.id, songToAdd.id, i));
          i++;
        }
      }

      showToast({
        duration: null,
        // id: ERROR_TOAST_ID,
        title: tCommons("messages.successfully"),
        variant: "left-accent",
        description: tCommons("messages.uploaded"),
      });
      navigate("/collections");
    };
    const update = useCallback(async () => {
      const songForm: any = new FormData();
      const songValues: any = formsValues.find(
        ({ form }) => form.name === "Album"
      );

      Object.keys(songValues?.values).forEach((key) => {
        if (
          songValues?.values[key] !== "" &&
          key !== "file" &&
          key !== "thumbnail" &&
          key !== "lyrics"
        ) {
          songForm.append(key, songValues?.values[key]);
        }
      });

      songForm.append("id", defaultValues.id);

      await updateAlbum(songForm);

      let removed:any[] = [];

      albumSongs.forEach((existingSong:any)=> {
        let found = selectedSongs.find((song:any)=> {return song.id===existingSong.id});
        if(!found){
          removed.push(existingSong.id);
        }
      });

      for (const songToRemove of removed) {
        removeSongFromAlbum({albumId: defaultValues.id, songId:songToRemove});
      }

      if(selectedSongs&&selectedSongs.length>0) {
        let i = 0;
        for (const songToAdd of selectedSongs) {
          uploadAlbumSongApi(generateSongToAlbumAndIndexUrl(defaultValues.id, songToAdd.id, i));
          i++;
        }
      }

      showToast({
        duration: null,
        // id: ERROR_TOAST_ID,
        title: tCommons("messages.successfully"),
        variant: "left-accent",
        description: tCommons("messages.updated"),
      });
      navigate("/collections");
    }, [uploadAlbumApi, formsValues]);

    const successHandler = useCallback(
      (data: any) => {
        if (isLast) {
          if (defaultValues) {
            update();
          } else {
            uploadAlbum();
          }
        } else {
          if(currentForm.id != "songs") {
            setAlbumForm(data);
          }
          nextForm();
        }
      },
      [isLast, uploadAlbum, nextForm, formState]
    );

      const addToSelected = (song:any)=> {
        setSelectedSongs([...selectedSongs, song]);
      }

    return (
      <VStack py="8" px="6" space="6" maxW="1500px">
        <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
          <SectionHeader title="Add new song" />
        </HStack>

        <SectionStepper steps={stepNames} currentStep={currentForm.name} />

        <CommonSection
          title={currentForm.name}
          backButton={isFirst ? t("buttons.cancel") : t("buttons.previous")}
          nextButton={isLast ? t("buttons.publish") : t("buttons.next")}
          nextButtonProps={{ isLoading: isLoading }}
          onNextClick={() =>
            submit({
              onSuccess: successHandler
            })
          }
          onBackClick={() => (isFirst ? navigate("/music") : prevForm())}
        >
          {
            currentForm.id == 'songs' && 
            <HStack space={6}>
              <VStack>
                  <Heading size={"xs"}>{t("title.albumSongs")} <Button onClick={onOpen}>{t("buttons.addSong")}</Button></Heading>
                  <SongsCards songs={selectedSongs} setSelectedSongs={setSelectedSongs}></SongsCards>
              </VStack>
              <VStack>

              </VStack>
            </HStack>
          }
          {isLast ? (
            <SummarySection
              sections={sections.filter(
                ({ title }) => title !== t("summary.title")
              )}
            />
          ) : (
            <WizardFormRender name={currentForm.name} />
          )}
        </CommonSection>
        <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Box w="100%" h={60} px={4} justifyContent="center">
              <SearchField onSearch={(title:string)=>{
                getSongs({title})
              }} isLoading={isLoadingSongs}></SearchField>
          </Box>
          { songs?.content?.map((song:any)=>{
            const isSelected = selectedSongs.filter((c:any) => `${c.id}` === song.id)[0];
            return <Actionsheet.Item><HStack space={2}>
                {!isSelected && <Button size={'xs'} onClick={()=> {addToSelected(song)}}><IconPlus size={10}></IconPlus></Button>}
                {isSelected && <CheckCircleIcon></CheckCircleIcon>}
                  <Text>{song.title}</Text></HStack>
              </Actionsheet.Item>
          })}         
        </Actionsheet.Content>
      </Actionsheet>        
      </VStack>
    );
  },
  () => {
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const location = useLocation();
    const defaultValues = location?.state?.data;

    return [
      {
        name: "Album",
        order: 1,
        fields: [
          {
            type: "text",
            label: `${t("album.fields.title.label")}*`,
            form: {
              name: "title",
              defaultValue: defaultValues?.title ?? "",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.language.label")}*`,
            form: {
              name: "language",
              defaultValue: defaultValues?.language ?? "Ukrain",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.artist.label")}*`,
            form: {
              name: "artist",
              defaultValue: defaultValues?.artist ?? "Anilorak",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.additionalArtist.label")}`,
            helperText: "Separa con “,” cada artista adicional",
            form: {
              name: "additionalArtists",
              defaultValue: "",
            },
          },

          {
            type: "date",
            label: `${t("album.fields.date.label")}*`,
            form: {
              name: "releasedAt",
              defaultValue: commonDateFormat(
                new Date(defaultValues?.releasedAt ?? Date.now())
              ),
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: "Copyrights",
            form: { name: "copyright", defaultValue: "" },
          },
          {
            type: "options",
            label: `${t("songs.fields.genre.label")}*`,
            options: [
              { value: "Rock", label: "Rock" },
              { value: "Pop", label: "Pop" },
            ],
            form: {
              name: "genre",
              defaultValue: defaultValues?.genre ?? "",
              rules: { required: "Required field" },
            },
          },
          {
            type: "options",
            label: `${t("album.fields.collectionType.label")}*`,
            options: [
              { value: "ALBUM", label: "ALBUM" },
              { value: "PODCAST", label: "PODCAST" },
              { value: "SINGLE", label: "SINGLE" },
            ],
            form: {
              name: "collectionType",
              defaultValue: defaultValues?.collectionType ?? "",
              rules: { required: "Required field" },
            },
          },
          {
            type: "file",
            label: `${t("album.fields.cover.label")}*`,
            supportedFile: SupportedFileTypes.IMAGE,
            helperText: "Recommended size: 1400 x 1400 pixels",
            isDisabled: defaultValues?.thumbnail ? true : false,
            form: {
              name: "thumbnail",
              ...(defaultValues
                ? {}
                : { rules: { required: "Required field" } }),
            },
          },
        ],
      },
      {
        id: 'songs',
        name: t("summary.songs"),
        order: 2,
        fields: [],
      },      
      {
        name: t("summary.title"),
        order: 3,
        fields: [],
      },
    ];
  }
);
