import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en/en.json";
import es from "./es/es.json";
import ru from "./ru/ru.json";

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ru: {
    translation: ru,
  },
};
i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: "en",
  resources,
});
