import { Button, EmptyButton } from '@components'
import { IconTrash } from '@tabler/icons-react'
import { useTranslation } from '@utils/hooks'
import { AspectRatio, Box, Heading, HStack, Image, Stack, Text } from 'native-base'
import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const style = {
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

export const DraggableSongCard = memo(function DraggableCard({ song, moveCard, findCard, removeFromSelected }:any) {
    const {t} = useTranslation({ keyPrefix: "music.forms" }); 
    const originalIndex = findCard(song.id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "song",
      item: { id: song.id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveCard(droppedId, originalIndex)
        }
      },
    }),
    [song.id, originalIndex, moveCard],
  )
  const [, drop] = useDrop(
    () => ({
      accept: 'song',
      hover({ id: draggedId }:any) {
        if (draggedId !== song.id) {
          const { index: overIndex } = findCard(song.id)
          moveCard(draggedId, overIndex)
        }
      },
    }),
    [findCard, moveCard],
  )
  const opacity = isDragging ? 0 : 1
  return (
    <div ref={(node) => drag(drop(node))} style={{ ...style, opacity }}>
        <HStack space={2}>
            <Image rounded={'md'} style={{width: 40, height: 40}} fallbackSource={"https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png"} source={{ uri: song.thumbnail ? song.thumbnail : "https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png" }} alt="image" />
            <Text lineHeight={40}>{song.title}</Text>
            <EmptyButton onClick={() => removeFromSelected(song)}>
                  <IconTrash size={30} color="black" />
            </EmptyButton>
        </HStack>
  </div>

  )
})