import { GET_POTST_URL, postRefreshTokenApi } from "@api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "@utils/hooks";
import { storeJsonData, STORAGE, keycloak } from "@utils/services";
import { networkClient } from "api/networkClient";
import { useToast } from "native-base";
import { useEffect } from "react";

const ERROR_TOAST_ID = "networkError";
export const useNetworkInterceptor = () => {
  const { show, isActive, closeAll } = useToast();
  const { t } = useTranslation();
  const { mutateAsync: getNewToken } = useMutation(postRefreshTokenApi);

  useEffect(() => {
    networkClient.interceptors.request.use(async (config: any) => {
      const cb = () => {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
        return Promise.resolve(config);
      };

      return keycloak.updateToken(300).then(cb).catch(keycloak.login);
    });
    networkClient.interceptors.response.use(
      function (config: any) {
        closeAll();
        return config;
      },
      function onAxiosError(error: any) {
        if (!isActive(ERROR_TOAST_ID)) {
          show({
            duration: null,
            id: ERROR_TOAST_ID,
            title: t("commons.networkError.title"),
            variant: "left-accent",
            description: t("commons.networkError.description"),
          });
        }

        return Promise.reject(error);
      }
    );
  }, []);
};
