import { getNotificationsApi, GET_NOTIFICATIONS_URL } from "@api";
import { SectionHeader, Table, Button, ModalTypes } from "@components/";
import { useModal } from "@contexts/";
import { IconPlus } from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { useTranslation } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { HStack, Text, VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

export interface NotificationsProps extends InterfaceBoxProps {}

const notification = () => {
  return {
    title: "New album release",
    content: "see the new content",
    redirectTo: "/songs/album/123",
    creationDate: "12/12/1212",
    triggerDate: "12/12/1212",
  };
};
export async function fetchNotificationsOld(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: new Array(4).fill(notification()),
    pageCount: 0,
  };
}

export const Notifications: FC<NotificationsProps> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "notifications" });

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "title",
      header: t("table.columns.title"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "description",
      header: t("table.columns.content"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "channel",
      header: t("table.columns.channel"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "language",
      header: "LANGUAGE",
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "link",
      header: t("table.columns.link"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.creationDate"),
      footer: (props) => props.column.id,
      cell: (props) => {
        let val = props.getValue();
        if(val){
          let date = new Date(val.toString());
          return format(date, 'dd/MM/yyyy HH:mm')
        }
        return '';        
      }
    },
  ];
  const { showModal } = useModal();

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title="Notifications" />
        <Button
          py="3"
          px="6"
          bg="primary.400"
          rounded="xl"
          onClick={() => showModal(ModalTypes.CREATE_NOTIFICATION)}
        >
          <HStack alignItems="center" space="2">
            <IconPlus size={16} color="white" />
            <Text color="white">{t("buttons.addNotification")}</Text>
          </HStack>
        </Button>
      </HStack>

      <Table
        fetcher={mapToTableData(getNotificationsApi)}
        url={GET_NOTIFICATIONS_URL}
        columns={columns}
      />
    </VStack>
  );
};
