import { networkClient } from "./networkClient";

export const GET_COMMENTS_API = "/comments/moderate";

export const getCommentsApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get(queryKey[0]);
  return data;
};

export const deleteCommentApi = async (commentId:string) => {
  const { data } = await networkClient.delete(GET_COMMENTS_API + "/" + commentId);
  return data;
};