import { IPostResponse } from "@utilsmodels/PostResponse";
import { networkClient } from "./networkClient";

export const PUT_POST_URL = "/posts";

export const putPostApi = async ({
  form,
  post,
}: {
  form: FormData;
  post: IPostResponse;
}) => {
  const { data } = await networkClient.put(`${PUT_POST_URL}/${post.id}`, form, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
