import { IFormField } from "@utils/models/FormField";
import {
  FormControl,
  IFormControlProps,
  Stack,
  WarningOutlineIcon,
} from "native-base";
import VStack, {
  IVStackProps,
} from "native-base/lib/typescript/components/primitives/Stack/VStack";
import { ComponentType, FC, useEffect } from "react";
import { useController, UseControllerProps } from "react-hook-form";

type TwithFormFieldProps<C> = C & IFormField;

type componentProps<C> = C & {
  form: UseControllerProps;
  label: string;
  formContainerProps?: IVStackProps;
  helperText?: string;
};

export function withFormField<C>(
  Component: ComponentType<TwithFormFieldProps<C>>,
  initialProps = { formContainerProps: {} }
) {
  return ({
    form: { name, control, rules, defaultValue },
    formContainerProps,
    label,
    helperText,
    ...props
  }: componentProps<Omit<C, "onChange" | "value">>) => {
    const {
      field: { value, onChange },
      fieldState: { error },
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    return (
      <FormControl isInvalid={!!error} w="auto">
        <Stack {...initialProps.formContainerProps} {...formContainerProps}>
          <FormControl.Label>{label}</FormControl.Label>
          <Component
            value={value}
            onChange={onChange}
            errorMessage={error?.message}
            {...initialProps}
            {...(props as C)}
          />
        </Stack>
        <FormControl.HelperText>{helperText}</FormControl.HelperText>
      </FormControl>
    );
  };
}
