import { IPost } from "@utils/models/Post";
import { networkClient } from "./networkClient";

export const UPLOAD_POST_URL = "/posts";

export interface IUploadPayload {
  premium: string;
}
export const uploadPostApi = async (post: FormData) => {
  const { data } = await networkClient.post(UPLOAD_POST_URL, post, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
