import { Button } from "@components/";
import { useAuth } from "@contexts";
import {
  useIsMobile,
  useTranslation
} from "@utils/hooks";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { FormField } from "components/FormField";
import { HStack, Stack, Text, VStack } from "native-base";
import { useState } from "react";
import { useForm } from "react-hook-form";

export interface EditContentFormProps
  extends ICommonFormProps<{
   
  }> {}

export const EditContentForm: React.FC<EditContentFormProps> = ({
  defaultValues,
  options,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation({ keyPrefix: "commons" });
  const isMobile = useIsMobile();
  const { session } = useAuth();
  const { control, handleSubmit, formState, watch } = useForm<any>({
    defaultValues: defaultValues ?? {
      title: "",
      titleEn: "",
    },
  });

  const submit = async (form: any) => {

    setLoading(true);
    onSubmit({ key: defaultValues?.contentKey, values: {"en":form.titleEn, "ru": form.title} })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const urlFieldValue = watch("path");
 

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      px="6"
      py="4"
      space="2"
    //   minH={"420px"}
    >
      <VStack w={{ lg: "100%" }} space="4">
          <HStack space="1">
          <Text bold color="gray.500">Key: </Text>
          <Text bold color="gray.800">{defaultValues?.contentKey}</Text>
          </HStack>
          
          <FormField.Text form={{ control, name: "title" }} label={`${t("messages.russian")} ${t("messages.title")}`} />
          <FormField.Text form={{ control, name: "titleEn" }} label={`${t("messages.english")} ${t("messages.title")}`} />

        <Button onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("messages.upload")}
          </Text>
        </Button>
      </VStack>
    </Stack>
  );
};
