import { getVideosApi, VIDEOS_URL, queryClient } from "@api";
import {
  EmptyButton,
  Table,
  ModalTypes,
} from "@components/";
import {
  IconEdit,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import {  useTranslation } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { Person } from "components/Table/fetchData";
import { useModal } from "contexts/Modal";
import { HStack, Text, VStack, Checkbox, Image, useToast } from "native-base";
import { FC, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getContentTitlesApi, GET_CONTENT_TITLES } from "api/getContentTitlesApi";
import { EditContentForm } from "./forms/EditContentForm";
import { putContentApi } from "api/putContentApi";


export const EditContent: FC<any> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "commons" });
  const { showModal, hideModal } = useModal();
  const [hasLiveVideo, setHasLiveVideo] = useState<null | any>(null);
  const { mutateAsync: editContentApi } = useMutation(putContentApi);
  const navigate = useNavigate();
  const { show: showToast } = useToast();

  const upload = async (form: any) => {
    await editContentApi(form);
    showToast({
      duration: null,
      title: `${t("messages.successfully")} ${t("messages.updated")}`,
      variant: "left-accent",
    });
    queryClient.invalidateQueries([GET_CONTENT_TITLES]);
    hideModal();
  };

  const openEditModal = (data: any) => {
    showModal(ModalTypes.COMMON, {
      title: "Edit content",
      body: (
        <EditContentForm
          defaultValues={{ contentKey: data.key, title: data.values.ru, titleEn: data.values.en }}
          options={{ hasLiveVideo }}
          onSubmit={upload}
        />
      ),
    });
  };

  const columns: ColumnDef<Person>[] = [
    {
      accessorKey: "key",
      header: t("messages.key"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "values.ru",
      header: `${t("messages.russian")} ${t("messages.title")}`,
      footer: (props) => props.column.id,
    },
    {
        accessorKey: "values.en",
        header: `${t("messages.english")} ${t("messages.title")}`,
        footer: (props) => props.column.id,
      },
    {
      accessorKey: "id",
      header: "",
      cell: (item) => (
        <EmptyButton onClick={() => openEditModal(item.row.original)}>
          <IconEdit size={30} color="black" />
        </EmptyButton>
      ),
    },
  ];

  return (
    <VStack  space="6" w="100%">
      <Table
        columns={columns}
        fetcher={mapToTableData(getContentTitlesApi)}
        url={GET_CONTENT_TITLES}
      />
    </VStack>
  );
};
