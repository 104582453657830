import { IPostResponse } from "@utilsmodels/PostResponse";
import { GET_ALBUMS_URL } from "./getAlbumsApi";
import { networkClient } from "./networkClient";

// export const DELETE_POST_URL = "/albums";

export const deleteSongFromAlbum = async ({albumId, songId} : any) => {
  const { data } = await networkClient.delete(`${GET_ALBUMS_URL}/${albumId}/songs/${songId}`);
  return data;
};
