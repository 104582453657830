import { Button, DatePickerField } from "@components/";
import { withFormField } from "@utils/hoc";
import {
  useTranslation
} from "@utils/hooks";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import {
  Checkbox,
  HStack,
  Stack,
  Text,
  Box
} from "native-base";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

export interface UserFormProps
  extends ICommonFormProps<{
    title: string;
    url: string;
    [x: string]: any;
  }> {}

const DatePickerFieldForm = withFormField(DatePickerField);

const NCheckbox = (props: any) => <Checkbox {...props} defaultIsChecked={props.value} />;
const CheckboxFieldForm = withFormField(NCheckbox, {
  formContainerProps: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    space: 2
  },
});

export const UserForm: React.FC<UserFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation({ keyPrefix: "commons.messages" });

  const {
    control,
    handleSubmit,
    watch
  } = useForm<any>({ defaultValues })

  const submit = async (form: any) => {
    setLoading(true);
    onSubmit(form).then(() => setLoading(false));
  };

  const isPremium = watch("premium")

  return (
    <Stack
      direction="column"
      px="6"
      py="4"
      space="2"
    >
      <Box width="100px">
        <CheckboxFieldForm
          form={{ control, name: "premium" }}
          label={t("premium")}
        />
      </Box>

      {isPremium && (
        <Box width="200px">
          <DatePickerFieldForm
            label={t("expiration")}
            form={{ control, name: "expiration" }}
          />
        </Box>
      )}

      <HStack justifyContent="flex-end">
        <Button w="100px" onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("save")}
          </Text>
        </Button>
      </HStack>
    </Stack>
  );
};
