import React from "react";
import { Chart } from "react-google-charts";
import { useTheme } from "native-base";
import { ICommonChartProps } from "@utilsmodels/Chart";

const mock = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
];

export function MapChart({ data = mock, options }: ICommonChartProps) {
  const theme = useTheme();

  const newOptions = options ?? {
    // region: "002", // Africa
    colorAxis: {
      colors: [
        theme.colors.primary[400],
        theme.colors.primary[400],
        theme.colors.primary[400],
      ],
    },
    // backgroundColor: ,
    // datalessRegionColor: "#f8bbd0",
    // defaultColor: "#f5f5f5",
  };

  return (
    <Chart
      options={newOptions}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      // height="400px"
      data={data}
    />
  );
}
