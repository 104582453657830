import {
  IconChevronDown,
  IconChevronUp,
  IconMenu2,
  IconPoint,
  IconX,
} from "@tabler/icons-react";
import { useIsMobile } from "@utils/hooks";

import { EmptyButton, Animated } from "@components";
import {
  Box,
  HStack,
  Text,
  Link as NLink,
  VStack,
  useTheme,
  useBreakpointValue,
  useDisclose,
} from "native-base";
import { FC, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

export interface IMenuOption {
  leftIcon: any;
  rightIcon?: React.ReactNode;
  id: string;
  name: string;
  path: string;
  active?: boolean;
  options?: IMenuOption[];
}

export interface MenuProps {
  options: IMenuOption[];
}

export const Menu: FC<MenuProps> = ({ options }) => {
  const theme = useTheme();
  const location = useLocation();
  const activePath = useMemo(() => location.pathname.split("/")[1], [location]);
  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclose();
  const [subMenusOpen, setSubMenusOpen] = useState<any>({});

  const isSubMenuOpen = (path:string) => {
      return subMenusOpen[path] === true;
  }

  const subMenuClose = (path:string) => {
    let newSubMenus = {...subMenusOpen};
    newSubMenus[path] = false;
    setSubMenusOpen(newSubMenus);
  }

  const subMenuOpen = (path:string) => {
    let newSubMenus = {...subMenusOpen};
    newSubMenus[path] = true;
    setSubMenusOpen(newSubMenus);
  }

  return isMobile ? (
    <Animated.VStack
      borderBottomWidth="2"
      borderBottomColor="primary.300"
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
    >
      <HStack h="60px" bg="white" px="2">
        <EmptyButton onClick={() => (isOpen ? onClose() : onOpen())}>
          {isOpen ? (
            <IconX size={30} color={theme.colors.primary[300]} />
          ) : (
            <IconMenu2 size={30} color={theme.colors.primary[300]} />
          )}
        </EmptyButton>
      </HStack>

      <Animated.VStack
        overflowY={"hidden"}
        initial={{ height: 0 }}
        exit={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        px={isOpen ? "4" : "0"}
        pb={isOpen ? "4" : "0"}
      >
        {options.map(({ id, leftIcon: Icon, rightIcon, name, path, options }) => (
          <VStack key={id} space="2" mt="2">
            <HStack
              space="2"
              bg={activePath === path ? "secondary.200" : "transparent"}
              p="2"
              borderRadius="lg"
              alignItems="center"
            >
              <Icon
                size={26}
                color={
                  activePath === path
                    ? theme.colors.primary[300]
                    : theme.colors.secondary[600]
                }
              />

              <Link
                to={path}
                onClick={() => onClose()}
                style={{ textDecoration: "none" }}
              >
                <Text
                  color={
                    activePath === path
                      ? theme.colors.primary[300]
                      : theme.colors.secondary[800]
                  }
                >
                  {name}
                </Text>
              </Link>

              <Box flexGrow={1} />
              {options?.length &&
                (isSubMenuOpen(path) ? (
                  <EmptyButton onClick={()=> subMenuClose(path)}>
                    <IconChevronUp size={25} color="gray" />
                  </EmptyButton>
                ) : (
                  <EmptyButton onClick={()=> subMenuOpen(path)}>
                    <IconChevronDown size={25} color="gray" />
                  </EmptyButton>
                ))}
              <VStack px="2">
                {options?.map(({ id, path, name }) =>
                  isSubMenuOpen(path) ? (
                    <Link
                      key={id}
                      to={path}
                      onClick={() => onClose()}
                      style={{ textDecoration: "none" }}
                    >
                      <Text
                        color={
                          activePath === path
                            ? theme.colors.primary[300]
                            : theme.colors.secondary[800]
                        }
                      >
                        {name}
                      </Text>
                    </Link>
                  ) : null
                )}
              </VStack>
            </HStack>
          </VStack>
        ))}
      </Animated.VStack>
    </Animated.VStack>
  ) : (
    <VStack>
      {options.map(({ id, leftIcon: Icon, rightIcon, name, path, options }) => (
        <VStack key={id} space="2" mt="2">
          <HStack
            space="2"
            bg={activePath === path ? "secondary.200" : "transparent"}
            p="2"
            borderRadius="lg"
            alignItems="center"
          >
            <Icon
              size={26}
              color={
                activePath === path
                  ? theme.colors.primary[300]
                  : theme.colors.secondary[600]
              }
            />

            <Link
              to={options?.length ? "" : path}
              style={{ textDecoration: "none" }}
            >
              <Text
                color={
                  activePath === path
                    ? theme.colors.primary[300]
                    : theme.colors.secondary[800]
                }
              >
                {name}
              </Text>
            </Link>

            <Box flexGrow={1} />
            {options?.length &&
              (isSubMenuOpen(path) ? (
                <EmptyButton onClick={()=> subMenuClose(path)}>
                  <IconChevronUp size={25} color="gray" />
                </EmptyButton>
              ) : (
                <EmptyButton onClick={()=> subMenuOpen(path)}>
                  <IconChevronDown size={25} color="gray" />
                </EmptyButton>
              ))}
          </HStack>

          <VStack px="2" space="2">
            {options?.map(({ id, path:childPath, name }) =>
              isSubMenuOpen(path) ? (
                <Link
                  key={id}
                  to={childPath}
                  onClick={() => onClose()}
                  style={{ textDecoration: "none" }}
                >
                  <HStack alignItems="center" space="2">
                    <IconPoint size={14} color="gray" />
                    <Text
                      color={
                        activePath === childPath
                          ? theme.colors.primary[300]
                          : theme.colors.secondary[800]
                      }
                    >
                      {name}
                    </Text>
                  </HStack>
                </Link>
              ) : null
            )}
          </VStack>
        </VStack>
      ))}
    </VStack>
  );
};
