import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { networkClient } from "./networkClient";

export const GET_USERS_CANCELLATION = "/subscriptions/requestingCancellation";
export const DELETE_USERS_CANCELLATION = "/subscriptions/cancel";
export const GET_USERS_ABUSERS = "/abuse";

export interface UserResponse {}

export const getUsersCancellationApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get<IPaginationResponse<UserResponse>>(
    queryKey[0]
  );
  return data;
};

export const deleteUsersCancellationApi = async (payload: any) => {
  const { data } = await networkClient.delete<
    IPaginationResponse<UserResponse>
  >(DELETE_USERS_CANCELLATION, payload);
  return data;
};
