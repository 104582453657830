import { Button } from "@components";
import { useTranslation } from "@utils/hooks";
import { defaultThumbnailUrl } from "@utilsmock/defaultThumbnailUrl";
import { HStack, useToast, VStack, Box, Divider, AspectRatio, Image, Center, Stack, Heading, Text } from "native-base";
import { useEffect, useState } from "react";


export default (props:any) => {

const {t} = useTranslation({ keyPrefix: "music.forms" }); 

return (

<HStack space={6}>
{
    props?.albums?.map((album:any)=> {
        if(props.exclude && props.exclude.length > 0) {
          let excluded = props.exclude.find((toExclude:any)=> {return album.id === toExclude.id});
          if(excluded) {
            return;
          }
        }

        return (<Box alignItems="center">
        <Box minWidth={240} rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _dark={{
        borderColor: "coolGray.600",
        backgroundColor: "gray.700"
        }} _web={{
        shadow: 2,
        borderWidth: 0
        }} _light={{
        backgroundColor: "gray.50"
        }}>
          <Box>
            <AspectRatio w="100%" ratio={16 / 9}>
              <Image fallbackSource={"https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png"} source={{ uri: album.thumbnail ? album.thumbnail : "https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png" }} alt="image" />
            </AspectRatio>
            <Center bg="violet.500" _dark={{
            bg: "violet.400"
          }} _text={{
            color: "warmGray.50",
            fontWeight: "700",
            fontSize: "xs"
          }} position="absolute" bottom="0" px="3" py="1.5">
              {album.collectionType}
            </Center>
          </Box>
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {album.title}
              </Heading>
              <Text fontSize="xs" _light={{
              color: "violet.500"
            }} _dark={{
              color: "violet.400"
            }} fontWeight="500" ml="-0.5" mt="-1">
                {album.description}
              </Text>
            </Stack>
            <Text fontWeight="400">
             {props.addToSelected && <Button onClick={()=> props.addToSelected(album)}>{t("buttons.includeInThisAlbum")}</Button>} 
             {props.removeFromSelected && <Button onClick={() => props.removeFromSelected(album)}>{t("buttons.removeFromThisAlbum")}</Button>} 
            </Text>
          </Stack>
        </Box>
        </Box>)
    })
}

</HStack>);

}