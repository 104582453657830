import React, { isValidElement } from "react";
import ReactDOM from "react-dom/client";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
// import "./index.css";
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { fetchData, Person } from "./fetchData";

import { Box, HStack, VStack, Text, Divider } from "native-base";
import { SearchField } from "components/SearchField";
import { EmptyButton } from "components/EmptyButton";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

export interface TableProps<T> {
  url?: string;
  fetcher?: any;
  // (options: { pageIndex: number; pageSize: number }) => Promise<{
  //   rows: T[];
  //   pageCount: number;
  // }>;
  columns: ColumnDef<T>[];
  onDataSuccess?: (data: any) => void;
}
export function Table<T = any>({
  url,
  fetcher,
  columns,
  onDataSuccess,
}: TableProps<T>) {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const fetchDataOptions = {
    url,
    pageIndex,
    pageSize,
  };

  const dataQuery = useQuery(
    [url, fetchDataOptions],
    () => fetcher(fetchDataOptions),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: onDataSuccess,
    }
  );

  const defaultData = React.useMemo(() => [], []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
      totalPages: 0,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns,
    pageCount: dataQuery.data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  const goToPage = (page: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: page }));
  };

  const prevPage = () => {
    return pageIndex == 0 ? 0 : pageIndex - 1;
  };

  const nextPage = () => {
    return pageIndex + 1 == dataQuery.data?.totalPages
      ? pageIndex
      : pageIndex + 1;
  };

  return (
    <VStack space="4" shadow="2" rounded="xl">
      <VStack
        minW="400px"
        minH="600px"
        // maxH="800px"
        rounded="xl"
        bg="white"
        p="6"
        space="4"
      >
        {/* TODO ADD SEARCHFIELD*/}
        {/* <HStack justifyContent="flex-end">
          <Box w="400px">
            <SearchField />
          </Box>
        </HStack> */}

        <HStack px="6" bg="#F8FBFF" rounded="xl" py="4">
          {table.getHeaderGroups().map((headerGroup) => (
            <HStack key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const content = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                );
                const isComponent = isValidElement(content);

                return (
                  <HStack key={header.id} minW="200px" justifyContent="center">
                    {header.isPlaceholder ? null : isComponent ? (
                      content
                    ) : (
                      <Text bold color="secondary.800">
                        {content}
                      </Text>
                    )}
                  </HStack>
                );
              })}
            </HStack>
          ))}
        </HStack>

        <VStack space="2" overflowY="scroll" maxH="600px" pb="2">
          {table.getRowModel().rows.map((row) => {
            return (
              <VStack>
                <HStack
                  px="6"
                  alignItems="center"
                  space="2"
                  minH="100px"
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => {
                    const content = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    );
                    const isComponent = isValidElement(content);

                    return (
                      <HStack
                        key={cell.id}
                        minW="50px"
                        maxW="200px"
                        w="100%"
                        justifyContent="center"
                      >
                        <Text>{content}</Text>
                      </HStack>
                    );
                  })}
                </HStack>
                <Divider />
              </VStack>
            );
          })}
        </VStack>
      </VStack>
      <HStack justifyContent="space-between" px="6" py="4">
        <Text color={"text.400"}>
          Shown {dataQuery.data?.size} from {dataQuery.data?.totalElements}{" "}
          items
        </Text>
        <HStack space="2">
          <EmptyButton onClick={() => goToPage(prevPage())}>
            <IconChevronLeft size={16} color="black" />
          </EmptyButton>
          {table.getPageOptions().map((pageNumber, index) => (
            <EmptyButton
              w="6"
              h="6"
              rounded="full"
              backgroundColor={
                table.getState().pagination.pageIndex === index
                  ? "gray.300"
                  : "gray.100"
              }
              onClick={() => goToPage(pageNumber)}
            >
              <Text color={"text.400"}>{pageNumber + 1}</Text>
            </EmptyButton>
          ))}
          <EmptyButton onClick={() => goToPage(nextPage())}>
            <IconChevronRight size={16} color="black" />
          </EmptyButton>
        </HStack>
      </HStack>
    </VStack>
  );
}
