// import { useNavigation } from "@react-navigation/native";
import {
  EmptyButton,
  SectionHeader,
  Table,
  Button,
  data,
  ModalTypes,
  SwitchButton,
} from "@components/";
import {
  IconEdit,
  IconKey,
  IconPlus,
  IconTrash,
  IconMusic,
  IconAlbum,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { getAllSongsApi, GET_SONGS_URL } from "api/getAllSongsApi";
import { HStack, Box, Text, VStack, Image } from "native-base";

import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultThumbnailUrl } from "@utils/mock/defaultThumbnailUrl";
import { useModal } from "@contexts";
import { useMutation } from "@tanstack/react-query";
import { deleteSongApi } from "api/deleteSong";
import { deleteMediaApi } from "api/deleteMediaApi";
import { queryClient } from "@api";
import { useForm } from "react-hook-form";
import { url } from "inspector";
import { getAlbumsApi, GET_ALBUMS_URL } from "api/getAlbumsApi";
import { deleteAlbumApi } from "api/deleteAlbumApi";

export const useCollectionTables = () => {
  const { t } = useTranslation({ keyPrefix: "music" });
  const { t: tCommon } = useTranslation({ keyPrefix: "commons" });
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();
  const { mutateAsync: deleteSong } = useMutation(deleteSongApi);
  const { mutateAsync: deleteAlbum } = useMutation(deleteAlbumApi);
  const { mutateAsync: deleteMedia } = useMutation(deleteMediaApi);

  const url = GET_ALBUMS_URL;
  
  const fetcher = useMemo(
    () => mapToTableData(getAlbumsApi, "albums"),
    []
  );

  const remove = useCallback(
    (data: any) => {
      const runDeleteSong = async () => {
        await deleteSong({ songId: data.id });
        await deleteMedia({ mediaId: data.audioMediaId });
        await queryClient.invalidateQueries([GET_SONGS_URL]);
        hideModal();
      };
      const runDeleteAlbum = async () => {
        await deleteAlbum({ albumId: data.id });
        await queryClient.invalidateQueries([GET_ALBUMS_URL]);
        hideModal();
      };

      showModal(ModalTypes.CONFIRM, {
        title: tCommon("messages.deleteTitle"),
        description: tCommon("messages.deleteDescription"),
        onConfirm: runDeleteAlbum,
        onCancel: () => {
          hideModal();
        },
      });
    },
    []
  );

  const edit = useCallback(
    (data: any) => {
      navigate("create", {
        state: { data },
      });
    },
    []
  );

  const columns: ColumnDef<any>[] = useMemo(
    () =>
      [
            {
              accessorKey: "thumbnail",
              header: t("table.columns.cover"),
              cell: (item) => {
                return (
                  <Image
                    source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
                    alt="Post Image"
                    resizeMode="cover"
                    size="60px"
                    rounded="xl"
                    fallbackSource={defaultThumbnailUrl}
                  />
                );
              },
              footer: (props) => props.column.id,
            },
            {
              accessorKey: "collectionType",
              header: t("table.columns.collectionType"),
              footer: (props) => props.column.id,
            },            
            {
              accessorKey: "title",
              header: t("table.columns.title"),
              footer: (props) => props.column.id,
            },
            {
              accessorKey: "artist",
              header: t("table.columns.artists"),
              footer: (props) => props.column.id,
            },
            {
              accessorKey: "id",
              header: "",
              cell: (item) => (
                <EmptyButton onClick={() => remove(item.row.original)}>
                  <IconTrash size={30} color="black" />
                </EmptyButton>
              ),
            },
            {
              accessorKey: "id",
              cell: (item) => (
                <EmptyButton onClick={() => edit(item.row.original)}>
                  <IconEdit size={30} color="black" />
                </EmptyButton>
              ),
            },
          ],
    []
  );

  return {
    columns,
    fetcher,
    url,
  };
};
