import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPost } from "@utilsmodels/Post";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { networkClient } from "./networkClient";

export const GET_POTST_URL = "/posts";

export interface IGetAllPostsResponse
  extends IPaginationResponse<IPostResponse> {}

export const getPostsApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get<IGetAllPostsResponse>(
    GET_POTST_URL + queryKey[1]
  );
  return data;
};

export const getPostApi = async (postId:string) => {
  const { data } = await networkClient.get<IPostResponse>(GET_POTST_URL + "/" + postId);
  return data;
};