import { networkClient } from "./networkClient";

export const UPLOAD_ALBUM_URL = "/albums";

export interface IUploadAlbumPayload {
  title: string;
  releasedAt: string;
  artist: string;
  language: string;
  genre: string;
  additionalArtists: string;
  copyright: string;
  thumbnail: File;
}
export const postAlbumApi = async (post: any) => {
  const { data } = await networkClient.post(UPLOAD_ALBUM_URL, post, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
