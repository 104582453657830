// import { useNavigation } from "@react-navigation/native";
import {
  EmptyButton,
  SectionHeader,
  Table,
  Button,
  data,
  ModalTypes,
  SwitchButton,
} from "@components/";
import {
  IconEdit,
  IconKey,
  IconPlus,
  IconTrash,
  IconMusic,
  IconAlbum,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { getAllSongsApi, GET_SONGS_URL } from "api/getAllSongsApi";
import { HStack, Box, Text, VStack, Image } from "native-base";

import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { defaultThumbnailUrl } from "@utils/mock/defaultThumbnailUrl";
import { useModal } from "@contexts";
import { useMutation } from "@tanstack/react-query";
import { deleteSongApi } from "api/deleteSong";
import { deleteMediaApi } from "api/deleteMediaApi";
import { queryClient } from "@api";
import { useForm } from "react-hook-form";
import { useMusicTables } from "../hooks/useMusicTables";

export interface SongListProps {}
const SwitchButtonForm = withFormFieldProps(SwitchButton);

export const SongList: FC<SongListProps> = ({ ...props }) => {

  const { t } = useTranslation({ keyPrefix: "music" });

  const { t: tCommon } = useTranslation({ keyPrefix: "commons" });

  const { url, fetcher, columns, changeFilter } = useMusicTables();

  const navigate = useNavigate();

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={t("title")} />
        <HStack space="2">
          <Button
            py="3"
            px="6"
            bg="primary.400"
            rounded="xl"
            onClick={() => navigate("create")}
          >
            <HStack alignItems="center" space="2">
              <IconPlus size={16} color="white" />
              <Text color="white">{t("buttons.addSong")}</Text>
            </HStack>
          </Button>
          <Button
            py="3"
            px="6"
            variant="outline"
            rounded="xl"
            onClick={() => navigate("/collections/create")}
          >
            <HStack alignItems="center" space="2">
              <IconPlus size={16} color="brown" />
              <Text color="primary.400">{t("buttons.addAlbum")}</Text>
            </HStack>
          </Button>
        </HStack>
      </HStack>
      <Table columns={columns} fetcher={fetcher} url={url} />
    </VStack>
  );
};
