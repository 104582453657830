import {
  CommonSection,
  EmptyButton,
  RadioField,
  SectionHeader,
  Table,
  TextField,
  TextAreaField,
  SwitchButton,
} from "@components/";
import {
  IconTrash,
  IconPlus,
  IconKey,
  IconEdit,
  IconPassword,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import { VStack, HStack, Button, Image, Text, Switch } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Outlet, useNavigate } from "react-router-dom";

export interface SettingsProps extends InterfaceBoxProps {}

const SwitchButtonFrom = withFormFieldProps(SwitchButton);

export const Settings: FC<SettingsProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const { t } = useTranslation({ keyPrefix: "settings" });
  const { control, watch } = useForm<any>({ defaultValues: { view: "editProfile" } });


  const currentView = watch('view');

  useEffect( ()=> {
    navigate(currentView)
  },[currentView])

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={t("title")} />

        <SwitchButtonFrom
          form={{ control, name: "view" }}
          options={[
            {
              label: t("buttons.editProfile"),
              value: 'editProfile',
              Icon: IconEdit,
            },
            {
              label: t("buttons.editContent"),
              value: 'editContent',
              Icon: IconKey,
            },
          ]}
        />
      </HStack>

      <Outlet />
    </VStack>
  );
};
