import { withFormField } from "@utils/hoc";
import {
  SelectField,
  TextAreaField,
  TextField,
  UploadButton,
} from "components";

export const FormField = {
  Text: withFormField(TextField),
  TextArea: withFormField(TextAreaField),
  Select: withFormField(SelectField),
  ButtonFile: withFormField(UploadButton),
  // File: withFormField()
};
