import { getNotificationsApi, GET_NOTIFICATIONS_URL, getPostApi } from "@api";
import { SectionHeader, Table, Button, ModalTypes, EmptyButton } from "@components/";
import { useModal } from "@contexts/";
import { IconExternalLink, IconPlus, IconTrash, IconVideo } from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { useTranslation } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { HStack, Text, VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { GET_COMMENTS_API, deleteCommentApi } from "api/getCommentsApi";
import { useEntryActions } from "../Home/hooks/useEntryActions";
import { useMutation } from "@tanstack/react-query";
import { getReelApi } from "api/getReelsApi";
import { AlertDialog } from "native-base";
import { queryClient } from "@api";

export interface CommentsProps extends InterfaceBoxProps {}

const notification = () => {
  return {
    title: "New album release",
    content: "see the new content",
    redirectTo: "/songs/album/123",
    creationDate: "12/12/1212",
    triggerDate: "12/12/1212",
  };
};
export async function fetchCommentsOld(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: new Array(4).fill(notification()),
    pageCount: 0,
  };
}

export const Comments: FC<CommentsProps> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "comments" });

  const {
    mutateAsync: loadPost,
    isLoading: isPostLoading,
    data : post
  } = useMutation(
    (args: any) => (getPostApi(args))
  );
  
  const {
    mutateAsync: loadReel,
    isLoading: isReelLoading,
    data : reel
  } = useMutation(
    (args: any) => (getReelApi(args))
  );

  const {
    mutateAsync: doDeleteComment,
    isLoading: isDeletingComment,
    data : resp
  } = useMutation(
    (args: any) => (deleteCommentApi(args))
  );

  const {
    remove: removeReel,
    edit: editReel,
    upload: uploadReel,
  } = useEntryActions("reel");
  const {
    remove: removePost,
    edit: editPost,
    upload: uploadPost,
  } = useEntryActions("post");

  const openEntity = async (type: string, id: string) => {
    if(type == 'post'){
      loadPost(id).then((data)=> {
        editPost(data);
      });
    }
    if(type == 'reel'){
      loadReel(id).then((data)=> {
        editReel(data);
      });
    }
  }

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "comment",
      header: t("table.columns.content"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "postId",
      header: t("table.columns.entity"),
      footer: (props) => props.column.id,
      cell: (props) => {
        let row = props.row.original;
        if(row.postID) {
          return <EmptyButton onClick={() => {openEntity('post', row.postID);}}>
          <IconExternalLink color="black" size={20} />
        </EmptyButton>;
        } else {
          return <EmptyButton onClick={() => openEntity('reel', row.reelID)}>
          <IconExternalLink color="black" size={20} />
        </EmptyButton>;
        }
      }
    },

    {
      accessorKey: "userName",
      header: "USER",
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.creationDate"),
      footer: (props) => props.column.id,
      cell: (props) => {
        let val = props.getValue();
        if(val){
          let date = new Date(val.toString());
          return format(date, 'dd/MM/yyyy HH:mm')
        }
        return '';        
      }
    },
    {
      accessorKey: "id",
      header: "",
      footer: (props) => props.column.id,
      cell: (props) => {
        return <HStack><EmptyButton onClick={()=>{setSelectedComment(props.row.original); setIsOpen(true);}}><IconTrash></IconTrash></EmptyButton></HStack>;        
      }
    },
  ];
  const { showModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<any>();
  
  const onClose = () => setIsOpen(false);

  const deleteComment = ()=> {
    doDeleteComment(selectedComment.id).then(async ()=>{
      await queryClient.invalidateQueries([GET_COMMENTS_API]);
      onClose();
    })
  }

  const cancelRef = useRef(null);

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={t("title")} />
      </HStack>

      <Table
        fetcher={mapToTableData(getNotificationsApi)}
        url={GET_COMMENTS_API}
        columns={columns}
      />

  <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Are you sure you want to delete the comment?</AlertDialog.Header>
          <AlertDialog.Body>
            {selectedComment?.comment}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <HStack space={2}>
              <EmptyButton variant="unstyled" colorScheme="coolGray" onClick={onClose}>
                Cancel
              </EmptyButton>
              <Button isLoading={isDeletingComment} colorScheme="danger" onClick={()=> {deleteComment()} }>
                Delete
              </Button>
            </HStack>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </VStack>
  );
};
