import { FC, useState, useRef, useEffect } from "react";
const getFileTypeFromExtension = (extension: string) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const videoExtensions = ["mp4", "mov", "avi", "mkv"];

  if (imageExtensions.includes(extension.toLowerCase())) {
    return "image";
  }

  if (videoExtensions.includes(extension.toLowerCase())) {
    return "video";
  }

  return ""; // If the extension doesn't match any known types
};

const getFileTypeFromUrl = (url: string) => {
  const matches = url.match(/\.([a-zA-Z0-9]+)(?:\?.*)?$/);
  if (matches && matches.length >= 2) {
    const extension = matches[1];
    return getFileTypeFromExtension(extension);
  }
  return "";
};

export const FileThumbnail: FC<{
  file: File | string;
  width: string | number;
  height: string | number;
  play?: boolean;
}> = ({ file, width = 200, height = 200, play = false }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const videoRef: any = useRef();

  const generateThumbnail = (file: File | string) => {
    if (typeof file === "string") {
      setFileType(getFileTypeFromUrl(file));
      setThumbnailUrl(file);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileType = file.type.split("/")[0];
      setThumbnailUrl(e.target.result);
      setFileType(fileType);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    generateThumbnail(file);
  }, [generateThumbnail, file]);

  useEffect(() => {
    if (videoRef.current) {
      if (play) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }
  }, [videoRef, play]);

  return fileType === "video" ? (
    <video
      src={thumbnailUrl}
      width={width}
      height={height}
      ref={videoRef}
      controls
    ></video>
  ) : (
    <div style={{ backgroundColor: '#dedede'}}>
          <img src={thumbnailUrl} alt="Thumbnail" width={width} height={height} style={{ objectFit: 'contain'}}/>
      </div>
  );
};
