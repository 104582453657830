import { Button, SwitchButton } from "@components/";
import { useAuth } from "@contexts";
import { IconKey, IconLiveView } from "@tabler/icons-react";
import Vimeo from "@u-wave/react-vimeo";
import { withFormField } from "@utils/hoc";
import {
  useIsMobile,
  useTranslation
} from "@utils/hooks";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { IFileDef } from "@utilsmodels/FileItem";
import { FormField } from "components/FormField";
import { Box, Spinner, Stack, Text, VStack } from "native-base";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import YouTube from "react-youtube";

export interface AddYoutubeVideoFormProps
  extends ICommonFormProps<{
    title: string;
    url: string;
    [x: string]: any;
  }> {}

const SwitchButtonForm = withFormField(SwitchButton);

function getYouTubeVideoId(url: string) {
  // Regular expression pattern to match YouTube video ID in the URL
  const regex = /(?:\?v=|&v=|youtu\.be\/)(.*?)(?:&|$|\?|#)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    // Handle invalid URL or no match found
    return null;
  }
}
function getVimeoVideoId(url: string) {
  // Match the Vimeo video ID in the format https://vimeo.com/{videoId}
  let match = url.match(/vimeo\.com\/(\d+)/);

  // If there is no match, try the format https://vimeo.com/event/{videoId}
  if (!match) {
    match = url.match(/vimeo\.com\/event\/(\d+)/);
  }

  // If there is a match, return the extracted video ID, otherwise, return null
  return match ? match[1] : null;
}

const VimeoPlayer = ({ isLive, id }:any) => {
  return (
    isLive ? 
    <div >
      <iframe
        src={`https://vimeo.com/event/${id}/embed`}
        // frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style={{position:"absolute",top:0,left:0,width:"100%",height:"400px" }}
      ></iframe>
    </div>
     : 

     <Vimeo
     video={id}
style={{height:400, width: '100%'}}
responsive
     height={400}
     width="100%"
     onError={() => {}}
   />
  );
};

const VIDEO_TYPES = { VIMEO: "VIMEO", YOUTUBE: "YOUTUBE" };
export const AddVideoForm: React.FC<AddYoutubeVideoFormProps> = ({
  defaultValues,
  options,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation({ keyPrefix: "commons.messages" });
  const isMobile = useIsMobile();
  const { session } = useAuth();
  const { control, handleSubmit, formState, watch } = useForm<any>({
    defaultValues: defaultValues ?? {
      title: "",
      path: "",
      videoType: "YOUTUBE",
      premium: false,
      live: false,
    },
  });

  const submit = async (form: any) => {
    const newForm: any = new FormData();

    newForm.append("userID", `${session?.user.id}`);
    newForm.append("shouldPost", "false");
    newForm.append("description", form.title);
    newForm.append("title", form.title);
    newForm.append("url", form.path);
    newForm.append("live", form.live);
    newForm.append("id", form.id);
    newForm.append("videoType", form.videoType);

    form.thumbnail?.forEach((file: IFileDef) =>
      newForm.append(options?.edit ? "thumbnailFile": "thumbnail", file.file, file.file.name)
    );

    setLoading(true);
    onSubmit(newForm)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const urlFieldValue = watch("path");
  const videoType = watch("videoType");
  const isLive = watch("live");
  const videoId = useMemo(
    () =>
      videoType === VIDEO_TYPES.YOUTUBE
        ? getYouTubeVideoId(urlFieldValue)
        : getVimeoVideoId(urlFieldValue),
    [urlFieldValue, videoType]
  );

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      px="6"
      py="4"
      space="2"
      minH={"420px"}
    >
      <Box w={{ lg: "50%" }} h="100%" justifyContent="space-between">
        {videoId ? (
          videoType === VIDEO_TYPES.YOUTUBE ? (
            <YouTube
              videoId={videoId}
              opts={{
                height: "390",
                width: "100%",
              }}
            />
          ) : (
          <VimeoPlayer isLive={isLive} id={videoId}/>
          )
        ) : (
          <VStack space="4" alignItems="center">
            <Text>Ingrese una url de youtube valida para visualizar</Text>
            <Spinner />
          </VStack>
        )}
      </Box>
      <VStack w={{ lg: "50%" }} space="4">
        <VStack>
          <FormField.Text
            form={{ control, name: "title" }}
            label={t("title")}
          />
          <FormField.Text form={{ control, name: "path" }} label={t("url")} />
          <FormField.Select
            form={{ control, name: "videoType", defaultValue: "YOUTUBE" }}
            options={[
              { label: "VIMEO", value: VIDEO_TYPES.VIMEO },
              { label: "YOUTUBE", value: VIDEO_TYPES.YOUTUBE },
            ]}
            label={t("platform")}
          />
        </VStack>
        <FormField.ButtonFile
          form={{ name: "thumbnail", control }}
          label="Thumbnail"
        />
        <Box w="170px">
          <SwitchButtonForm
            isDisabled={options?.hasLiveVideo}
            form={{ control, name: "live" }}
            label={t("live")}
            options={[
              {
                label: t("yes"),
                value: true,
                Icon: IconLiveView,
              },
              {
                label: t("no"),
                value: false,
                Icon: IconKey,
              },
            ]}
          />
        </Box>

        <Button onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
             { options?.edit ? t("update") : t("upload")}
          </Text>
        </Button>
      </VStack>
    </Stack>
  );
};
