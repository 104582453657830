import { networkClient } from "./networkClient";

export const UPLOAD_SONG_TO_ALBUM_URL = "/albums";

export const generateSongToAlbumUrl = (albumId: string, songId: string) =>
  `${UPLOAD_SONG_TO_ALBUM_URL}/${albumId}/songs/${songId}/0`;

export const generateSongToAlbumAndIndexUrl = (albumId: string, songId: string, index: number) =>
  `${UPLOAD_SONG_TO_ALBUM_URL}/${albumId}/songs/${songId}/${index}`;

export interface IUploadSongPayload {}

export const postSongToAlbumApi = async (url: string) => {
  const { data } = await networkClient.post<IUploadSongPayload>(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
