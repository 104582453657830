import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";

export const mapToTableData =
  <F>(data: ({ queryKey }: any) => any, mapper?: string) =>
  async (options: {
    pageIndex?: number;
    pageSize?: number;
    url?: string;
    order?: string;
  }) => {
    try {
      const response = await data({
        queryKey: [
          `${options.url}?page=${options.pageIndex}&size=${
            options.pageSize
          }&sort=createdAt,${options?.order ?? "asc"}`,
        ], //&sort=createdAt,asc
      });
      return {
        rows: mapper ? response[mapper].content : response.content,
        pageCount: mapper ? response[mapper].number : response.number,
        ...response,
      };
    } catch (error) {
      return { rows: 0, pageCount: 0 };
    }
  };
