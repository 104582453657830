import { Button, SwitchButton } from "@components/";
import { IconCrop11, IconCropLandscape, IconCropPortrait, IconEdit, IconKey } from "@tabler/icons-react";
import { withFormField } from "@utils/hoc";
import {
  SupportedFileTypes,
  useIsMobile,
  useTranslation,
  withFormFieldProps,
} from "@utils/hooks";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { IFileDef } from "@utilsmodels/FileItem";
import { FileUpload } from "components/FileUpload";
import { FormField } from "components/FormField";
import { Box, Stack, Text, useToast, VStack } from "native-base";
import { useState } from "react";
import { useForm } from "react-hook-form";


export interface UploadPostFormProps
  extends ICommonFormProps<{
    files: [];
    description: "";
    title: "";
    premium: boolean;
  }> {
  premiumSwitch?: boolean;
  isPost? : boolean;
  supportedFileType: SupportedFileTypes;
}

const FieldUploadForm = withFormFieldProps(FileUpload);
const SwitchButtonForm = withFormField(SwitchButton);


export const UploadPostForm: React.FC<UploadPostFormProps> = ({
  supportedFileType,
  defaultValues,
  premiumSwitch = true,
  isLoading,
  onSubmit,
  isPost = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const isDisabled = supportedFileType === SupportedFileTypes.DISABLED;
  // const [tempValues, setTempValues] = useState<Record<keyof typeof LANGUAGES, { description?:string}>>({ EN: {}, RU: {}})
  const [tempValues, setTempValues] = useState<any>({ EN: { description: defaultValues?.descriptionEn }, RU: { description: defaultValues?.description } });
  const { control, handleSubmit, watch, formState, setValue, getValues } = useForm<any>({
    defaultValues: defaultValues
      ? { ...defaultValues   }
      : null ?? {
          files: [],
          description: "",
          descriptionEn: "",
          premium: false,
          aspectRatio: "SQUARE"
        },
  });

  const submit = (form: any) => {
    const newForm: any = new FormData();

    newForm.append("title", form.description);
    newForm.append(
      "description",
      form.description //? form.description : defaultValues?.description ?? null
    );
    newForm.append(
      "descriptionEn",
      form.descriptionEn //?? defaultValues?.descriptionEn ?? ""
    );
    newForm.append("premium", form.premium);
    newForm.append("aspectRatio", form.aspectRatio);

      if(!form.files||form.files.length == 0) {
        return;
      }

    if (!isDisabled) {
      form.files.map((file: IFileDef) =>
        newForm.append("files", file.file, file.file.name)
      );
    }
    form.thumbnail?.forEach((file: IFileDef) =>
      newForm.append("thumbnail", file.file, file.file.name)
    );

    newForm.append("latitude", 0);
    newForm.append("longitude", 0);
    setLoading(true);
    onSubmit(newForm)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);

      });
    // submitHandler(form);
  };


  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      overflowY="scroll"
      maxH={400}
    >
      <Box w={{ lg: "40%" }} height={{lg: "100%"}}>
        <FieldUploadForm
          form={{ control, name: "files", rules:{required:true} }}
          supportedType={supportedFileType ?? SupportedFileTypes.VIDEO}
          placeholder={t("home.modals.createPost.form.file.label")}
        />
      </Box>
      <VStack w={{ lg: "60%" }} px="6" py="6" space="4">
        <FormField.TextArea
          form={{
            control,
            name: 'description'
            // name: languageValue === LANGUAGES.RU ?  "description" : "descriptionEn",
          }}
          label={t("home.modals.createPost.form.description.label")}
        />
        <FormField.TextArea
          form={{
            control,
            name: 'descriptionEn'
          }}
          label={t("home.modals.createPost.form.descriptionEn.label")}
        />
        <FormField.ButtonFile
          form={{ name: "thumbnail", control }}
          label="Thumbnail"
        />
        <Box w="80%">
          {isPost ? (
            <SwitchButtonForm
              style={{width: '100%'}}
              form={{ control, name: "aspectRatio" }}
              label={t("home.modals.createPost.form.aspectRatio.label")}
              options={[
                {
                  label: t("home.modals.createPost.form.aspectRatio.options.SQUARE"),
                  value: "SQUARE",
                  Icon: IconCrop11,
                },
                {
                  label: t("home.modals.createPost.form.aspectRatio.options.PORTRAIT"),
                  value: "PORTRAIT",
                  Icon: IconCropPortrait,
                },
                {
                  label: t("home.modals.createPost.form.aspectRatio.options.LANDSCAPE"),
                  value: "LANDSCAPE",
                  Icon: IconCropLandscape,
                },

              ]}
            />
          ) : (
            <></>
          )}
        </Box>
        <Box w="170px">
          {premiumSwitch ? (
            <SwitchButtonForm
              form={{ control, name: "premium" }}
              label="Premium content"
              options={[
                {
                  label: t("home.modals.createPost.form.premium.options.yes"),
                  value: true,
                  Icon: IconEdit,
                },
                {
                  label: t("home.modals.createPost.form.premium.options.no"),
                  value: false,
                  Icon: IconKey,
                },
              ]}
            />
          ) : (
            <></>
          )}
        </Box>

        <Button isDisabled={!formState.isValid} onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("home.modals.createPost.form.submitButton")}
          </Text>
        </Button>
      </VStack>
    </Stack>
  );
};
