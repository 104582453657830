import { networkClient } from "./networkClient";

export const createMembership = async (payload: any) => {
  const { data } = await networkClient.post(
    `/memberships`,
  { userID: payload.userId, expiration: payload.expiration }
  );
  return data;
};

export const deleteMembership = async (payload: any) => {
  const { data } = await networkClient.delete(
    `/subscriptions/cancel/${payload.membershipId}`
  );
  return data;
};